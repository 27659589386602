import Container from '../../components/Container';
import Header from '../../components/Header';
import CheckInForm from '../../forms/CheckInForm';

const GuestCheckInPage = () => {
    return (
        <Container containerName={'landing'}>
            <Header title={'Scan the ID'} subtitle={"Scan the guest's ID"} padding={true}/>
            <CheckInForm />
        </Container>
    );
};

export default GuestCheckInPage;
