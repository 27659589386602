import EditIcon from '../img/edit.svg';
import DeleteIcon from '../img/delete.svg';
import UserListI from '../interfaces/UserListI';

const UserList = ({ users, input, handleEdit, openModal }: UserListI) => {
    if (users.length < 1) {
        return <></>;
    }

    return (
        <>
            {users
                .filter(
                    (user) =>
                        input.trim() === '' ||
                        user.login.toLowerCase().includes(input)
                )
                .map((user) => (
                    <tr key={user._id}>
                        <td>{user.login}</td>
                        <td>
                            <button
                                className="user-button-icon"
                                onClick={handleEdit}
                            >
                                <img
                                    className={user._id}
                                    src={EditIcon}
                                    alt="edit Icon"
                                />
                            </button>
                        </td>
                        <td>
                            <button
                                className="user-button-icon"
                                onClick={openModal}
                            >
                                <img
                                    className={user._id}
                                    src={DeleteIcon}
                                    alt="delete Icon"
                                />
                            </button>
                        </td>
                    </tr>
                ))}
        </>
    );
};

export default UserList;
