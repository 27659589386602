import GuestForm from '../../forms/GuestForm';
import Container from '../../components/Container';
import Header from '../../components/Header';

const EditGuestPage = () => {
    return (
        <Container containerName={'landing'}>
            <Header title={'Edit Guest'} subtitle={''} bottomBorder={true} />
            <GuestForm action={'edit'} />
        </Container>
    );
};

export default EditGuestPage;
