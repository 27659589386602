import axios from 'axios';
import parseDate from './parseDate';
import { saveAs } from 'file-saver';
import GuestI from '../interfaces/Guest';
import User from '../interfaces/User';

const getAgreement = async (
    guest: GuestI,
    user: User,
    setLoading: (value: boolean) => void,
    index: number
) => {
    setLoading(true);
    try {
        const entryDate = guest.entryDates && guest.entryDates[index];

        const fileBlob = await axios.post(
            '/api/guests/agreement',
            {
                guest: guest,
                user: user,
                entryDate: entryDate,
            },
            { responseType: 'blob' }
        );

        const fileName = `${parseDate(entryDate)}_${guest.firstName}-${
            guest.lastName
        }.pdf`;

        const file = new Blob([fileBlob.data], { type: 'application/pdf' });

        await saveAs(file, fileName);
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
    }
};

export default getAgreement;
