import axios from 'axios';
import FeeI from '../interfaces/Fee';
import GuestI from '../interfaces/Guest';
import AllAccessGuestI from '../interfaces/AllAccessGuest';
import getPoints from './getPoints';

const updateGuestPoints = async (
    feeType: string,
    { value }: FeeI,
    {
        points,
        _id,
        type,
        guestID,
        firstName,
        lastName,
        stageName,
        active,
    }: GuestI | AllAccessGuestI,
    setLoading: any,
    history: any,
    modified: boolean,
    renewalStatus: boolean,
    discount: any
) => {
    setLoading(true);

    let total = value;

    if (discount !== undefined && !modified && type !== 'limited') {
        total = value + discount.value;

        if (total < 0) {
            total = 0;
        }
    }

    try {
        let guestPoints = renewalStatus ? points : getPoints(points, active);

        if (guestPoints > 5) {
            guestPoints = 5;
        }

        if (guestPoints < 0) {
            guestPoints = 0;
        }

        if (type === 'allAccess') {
            await axios.patch(`/api/guests/${_id}?type=${type}`, {
                points: guestPoints,
                lifeTimePoints: getPoints(points, active),
                lifeTimeFees: total,
                entryDates: [new Date()],
            });
        } else {
            await axios.patch(`/api/guests/${_id}?type=${type}`, {
                lifeTimeFees: total,
                entryDates: [new Date()],
            });
        }

        await axios.post('/api/transactions', {
            guestID: guestID,
            firstName: firstName,
            lastName: lastName,
            stageName: stageName || 'NONE',
            fee: feeType,
            type: type,
            value: total,
            points: guestPoints,
        });

        return history.push(`/guests/checkin/${type}`);
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
    }
};

export default updateGuestPoints;
