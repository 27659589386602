import { useEffect, useState } from 'react';
import GuestI from '../interfaces/Guest';
import AllAccessGuestI from '../interfaces/AllAccessGuest';

const useAge = ({ dateOfBirth }: GuestI | AllAccessGuestI) => {
    const [age, setAge] = useState<number>(0);

    useEffect(() => {
        const birthDate = new Date(dateOfBirth as Date);
        const date = new Date();

        setAge(date.getFullYear() - birthDate.getFullYear());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        age,
        setAge,
    };
};

export default useAge;
