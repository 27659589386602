import Button from '../components/Button';
import getDefaultDate from '../functions/getDefaultDate';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import Form from '../components/Form';

type TransactionDate = { inputDate: string };

const TransactionLookUpForm = () => {
    const date = getDefaultDate();
    const history = useHistory();
    const { register, handleSubmit } = useForm<TransactionDate>();

    const handleDate: SubmitHandler<TransactionDate> = ({ inputDate }) => {
        const [year, month, day] = inputDate.split('-');

        return history.push(`/transactions/${month}-${day}-${year}`);
    };

    return (
        <Form onSubmit={handleSubmit(handleDate)}>
            <h3 className="transaction-container__text">
                Select date for look-up
            </h3>

            <input
                {...register('inputDate', { required: true })}
                className="select-input"
                type="date"
                defaultValue={date}
            />

            <Button color={'green'} margin={true}>
                Continue
            </Button>
            <Button
                color={'red'}
                margin={true}
                onClick={() => history.push('/dashboard')}
            >
                Back
            </Button>
        </Form>
    );
};

export default TransactionLookUpForm;
