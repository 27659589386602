import axios from 'axios';
import FeeI from '../interfaces/Fee';
import GuestI from '../interfaces/Guest';
import AllAccessGuestI from '../interfaces/AllAccessGuest';

const fetchTransaction = async (
    { value }: FeeI,
    feeType: string,
    {
        guestID,
        firstName,
        lastName,
        stageName,
        type,
        points,
    }: GuestI | AllAccessGuestI,
    addGuest: any = () => null
) => {
    try {
        await axios.post('/api/transactions', {
            guestID: guestID,
            firstName: firstName,
            lastName: lastName,
            stageName: stageName || 'NONE',
            fee: feeType,
            type: type,
            value: value,
            points: points || 0,
        });
    } catch (error) {
        console.log(error);
    } finally {
        await addGuest();
    }
};

export default fetchTransaction;
