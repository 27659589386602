import parseDate from './parseDate';

const getDefaultDate = () => {
    const date = parseDate();
    const [day, year, month] = date.split('-');

    return `${month}-${day}-${year}`;
};

export default getDefaultDate;
