import Modal from 'react-modal';
import Header from '../Header';
import Button from '../Button';
import { ChangeEvent } from 'react';
import FeeI from '../../interfaces/Fee';

const NoticeGuestModal = ({
    fee,
    setFee,
    day,
    today,
    isOpen,
    setIsOpen,
}: {
    fee: FeeI | null;
    setFee: (fee: FeeI) => void;
    day: number;
    today: string;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}) => {
    const handleFee = (e: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);

        setFee({ value: value, day: day, name: today });
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            ariaHideApp={false}
            className="modal"
        >
            <div>
                <Header
                    title={'Override Fee'}
                    subtitle={'Enter the new Fee Amount'}
                />
                <div>
                    <span className="form-span">Today's fee:</span>
                    <input
                        type="number"
                        className="form-input"
                        defaultValue={fee ? fee.value : 0}
                        onChange={handleFee}
                    />
                </div>
                <div>
                    <Button
                        color={'green'}
                        margin={true}
                        onClick={() => setIsOpen(false)}
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default NoticeGuestModal;
