import TransactionI from '../interfaces/Transaction';
import TransactionsI from '../interfaces/Transactions';

const getTransaction = (id: string, transactions: TransactionsI) => {
    if (!transactions) {
        return null;
    }

    const limited = transactions.limited.find(
        (transaction: TransactionI) => transaction._id === id
    );
    const allAccess = transactions.allAccess.find(
        (transaction: TransactionI) => transaction._id === id
    );

    if (limited === undefined) {
        return allAccess;
    }

    return limited;
};

export default getTransaction;
