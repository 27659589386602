import FlexContainer from './FlexContainer';
import TransactionTable from './TransactionTable';
import TransactionI from '../interfaces/Transaction';
import editTransactionGuest from '../functions/editTransactionGuest';
import { MouseEvent, useContext, useState } from 'react';
import getTransaction from '../functions/getTransaction';
import TransactionsI from '../interfaces/Transactions';
import { useHistory } from 'react-router-dom';
import GlobalContext from '../contexts/GlobalContext';
import Action from '../interfaces/Action';
import TransactionModal from './modals/TransactionModal';
import Button from './Button';

const Transactions = ({
    transactions,
    transaction,
    date,
    setTransaction,
    setLoading,
    setAction,
}: {
    transactions: TransactionsI | null;
    transaction: TransactionI | null;
    date: string;
    setTransaction: (transaction: TransactionI) => void;
    setLoading: (value: boolean) => void;
    setAction: (action: Action) => void;
}) => {
    const { setGuest } = useContext(GlobalContext);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const history = useHistory();

    const handleEdit = async (e: any) => {
        if (!transactions) {
            return;
        }

        const transactionAllAccess = transactions.allAccess.find(
            (transaction: TransactionI) =>
                transaction._id === e.target.parentElement.id
        );
        const transactionLimited = transactions.limited.find(
            (transaction: TransactionI) =>
                transaction._id === e.target.parentElement.id
        );

        const transaction =
            transactionAllAccess !== undefined
                ? transactionAllAccess
                : transactionLimited;

        await editTransactionGuest(
            transaction as TransactionI,
            setGuest,
            history
        );
    };

    const handleEditTransaction = (e: MouseEvent<HTMLButtonElement>) => {
        const transaction = getTransaction(
            e.currentTarget.children[0].className,
            transactions as TransactionsI
        );

        setTransaction(transaction as TransactionI);
        setAction({ status: true, type: 'edit' });
    };

    const openModal = (e: MouseEvent<HTMLButtonElement>) => {
        const transaction = getTransaction(
            e.currentTarget.children[0].className,
            transactions as TransactionsI
        );

        setTransaction(transaction as TransactionI);
        setIsOpen(true);
    };

    return (
        <FlexContainer>
            <h3 className="transaction-container__text">
                Date selected for look up:{' '}
                <span className="red-span">{date}</span>
            </h3>
            {transactions !== null && (
                <>
                    <TransactionTable
                        transactions={transactions.allAccess}
                        handleEdit={handleEdit}
                        handleEditTransaction={handleEditTransaction}
                        openModal={openModal}
                        allAccess={true}
                    />
                    <TransactionTable
                        transactions={transactions.limited}
                        handleEdit={handleEdit}
                        handleEditTransaction={handleEditTransaction}
                        openModal={openModal}
                        allAccess={false}
                    />
                </>
            )}
            <Button
                color={'green'}
                margin={true}
                onClick={() =>
                    setAction({
                        status: true,
                        type: 'statistics',
                    })
                }
            >
                Statistics
            </Button>
            <Button
                color={'red'}
                margin={true}
                onClick={() => history.push('/transactions')}
            >
                Go back
            </Button>
            <TransactionModal
                transaction={transaction}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                setLoading={setLoading}
                setAction={setAction}
            />
        </FlexContainer>
    );
};

export default Transactions;
