import axios from 'axios';
import GuestI from '../interfaces/Guest';
import AllAccessGuestI from '../interfaces/AllAccessGuest';

const updateGuestSignature = async (
    { _id, type, tos, lease, guestID }: GuestI | AllAccessGuestI,
    sigPad: any,
    history: any,
    setLoading: any,
    tosPage: boolean = true,
    checkbox: boolean = true
) => {
    setLoading(true);
    try {
        if (!checkbox || sigPad.isEmpty()) {
            return;
        }

        const url = tosPage
            ? `/guests/confirmation/${type}`
            : `/guests/checkin/${type}/${guestID}/update`;

        const signature = sigPad.getTrimmedCanvas().toDataURL('image/png');
        const base64 = signature.split(',')[1];
        // const buffer = Buffer.from(base64, 'base64');

        if (!tos && !lease) {
            await axios.patch(`/api/guests/${_id}?type=${type}`, {
                tos: true,
                lease: true,
            });
        }

        await axios.post('/api/signatures/create', {
            signature: base64,
            guestID: guestID,
            type: type,
        });

        return history.push(url);
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
    }
};

export default updateGuestSignature;
