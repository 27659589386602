import getMaxPageNumber from '../functions/getMaxPageNumber';
import GuestI from '../interfaces/Guest';

const DatabasePagination = ({
    pageNumber,
    setPageNumber,
    filteredGuests,
}: {
    pageNumber: number;
    setPageNumber: (value: number) => void;
    filteredGuests: GuestI[];
}) => {
    const handleNextPage = () => {
        if (pageNumber + 1 > getMaxPageNumber(filteredGuests)) {
            return;
        }

        return setPageNumber(pageNumber + 1);
    };

    const handlePreviousPage = () => {
        if (pageNumber - 1 < 1) {
            return;
        }

        return setPageNumber(pageNumber - 1);
    };

    return (
        <>
            <p className="user-container__text">
                Page {pageNumber} of {getMaxPageNumber(filteredGuests)}
            </p>
            <div>
                <button className="green-button-page" onClick={handleNextPage}>
                    Next Page
                </button>
                <button
                    className="yellow-button-page"
                    onClick={handlePreviousPage}
                >
                    Previous Page
                </button>
            </div>
        </>
    );
};

export default DatabasePagination;
