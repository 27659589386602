import { ChangeEvent, useState } from 'react';
import TransactionStatisticsProps from '../interfaces/TransactionStatisticsProps';
import useStatistics from '../hooks/useStatistics';
import Options from './Options';
import getSortedTransactions from '../functions/getSortedTransactions';
import FlexContainer from './FlexContainer';

const TransactionStatistics = ({
    setAction,
    date,
    transactions,
}: TransactionStatisticsProps) => {
    const { statistics, setStatistics } = useStatistics(transactions);
    const [hour, setHour] = useState<number>(0);
    const [displayHourStatistic, setDisplayHourStatistic] = useState<boolean>(
        false
    );

    const handleDropdown = (e: ChangeEvent<HTMLSelectElement>) => {
        if (!statistics) {
            return;
        }

        if (e.target.value === 'default') {
            setDisplayHourStatistic(false);
            return;
        }

        const time = {
            value: parseInt(e.target.value.split(' ')[0]),
            type: e.target.value.split(' ')[1],
        };

        const { transactionsBefore, transactionsAfter } = getSortedTransactions(
            transactions,
            time
        );

        setHour(parseInt(e.target.value));
        setDisplayHourStatistic(true);
        setStatistics({
            ...statistics,
            limitedAccessBefore: transactionsBefore.length,
            limitedAccessAfter: transactionsAfter.length,
        });
    };

    return (
        <FlexContainer>
            <div>
                <h3 className="landing-container__text">
                    Showing details for:{' '}
                    <span className="green-span">{date}</span>
                </h3>
            </div>

            <div>
                <p className="transaction-box">
                    <span className="red-span">Today's All Access Guests:</span>{' '}
                    {statistics?.allAccessGuests || '0'}
                </p>
                {displayHourStatistic && (
                    <>
                        <p className="transaction-box">
                            <span className="guest-span-date">
                                Today's Limited Access Guests Before {hour}:
                            </span>{' '}
                            {statistics?.limitedAccessBefore || '0'}
                        </p>
                        <p className="transaction-box">
                            <span className="red-span">
                                Today's Limited Access Guests After {hour}:
                            </span>{' '}
                            {statistics?.limitedAccessAfter || '0'}
                        </p>
                    </>
                )}
                <p className="transaction-box">
                    <span className="red-span">
                        Today's Limited Access Guests:
                    </span>{' '}
                    {statistics?.limitedAccessGuests || '0'}
                </p>
                <p className="transaction-box">
                    <span className="red-span">Today's All Access Fees:</span>{' '}
                    {statistics?.allAccessFees || '0'}$
                </p>
                <p className="transaction-box">
                    <span className="red-span">
                        Today's Limited Access Fees:
                    </span>{' '}
                    {statistics?.limitedAccessFees || '0'}$
                </p>
                <p className="transaction-box">
                    <span className="guest-span-date">Total Fees:</span>{' '}
                    {statistics?.totalFees || '0'}$
                </p>
            </div>

            <div>
                <span className="form-span">
                    Select hour to view transactions before and after
                </span>
                <select
                    className="select-input"
                    name="after"
                    defaultValue="default"
                    onChange={handleDropdown}
                >
                    <option value="default">select hour...</option>
                    <Options />
                </select>
            </div>
            <div>
                <button
                    className="red-button margin"
                    onClick={() =>
                        setAction({ status: false, type: 'statistics' })
                    }
                >
                    Go back
                </button>
            </div>
        </FlexContainer>
    );
};
export default TransactionStatistics;
