import axios from 'axios';
import GuestI from '../interfaces/Guest';
import AllAccessGuestI from '../interfaces/AllAccessGuest';

const getGuestByGuestID = async (guestID: string, type: string) => {
    try {
        const { data } = await axios.get(
            `/api/guests/single?guestID=${guestID.toLowerCase()}&type=${type}`
        );

        const guest: GuestI | AllAccessGuestI = data;

        return guest || null;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export default getGuestByGuestID;
