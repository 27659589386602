import { useState } from 'react';
import Loading from '../components/Loading';
import FeeI from '../interfaces/Fee';
import FeeFormProps from '../interfaces/FeeFormProps';
import Options from '../components/Options';
import parseHour from '../functions/parseHour';
import { SubmitHandler, useForm } from 'react-hook-form';
import getMilitaryTime from '../functions/getMilitaryTime';
import useDiscountFee from '../hooks/useDiscountFee';
import { compact } from 'lodash';
import updateFee from '../functions/updateFee';
import Form from '../components/Form';

const DiscountForm = ({ feeType, setAction }: FeeFormProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const { fees } = useDiscountFee(feeType, setLoading);
    const { register, handleSubmit } = useForm<FeeI>();

    const handleSave: SubmitHandler<FeeI> = async (data) => {
        if (!data) {
            setAction({ status: false, type: feeType });
            return;
        }

        const feesData = Object.values(data).map((value, index) => {
            const name = Object.keys(data)[index];

            if (name === 'Late Event Fee') {
                return {
                    beforeHour: fees[1].beforeHour,
                    name: name,
                    value: value,
                };
            }

            if (name.includes('Before')) {
                return null;
            }

            return {
                beforeHour: Object.values(data)[index + 1],
                name: Object.keys(data)[index],
                value: value,
            };
        });

        await updateFee(
            compact(feesData) as FeeI[],
            feeType,
            setLoading,
            setAction
        );
    };

    if (loading) {
        return <Loading loading={loading} />;
    }

    return (
        <>
            <div>
                <h3 className="edit-fee-container__text">
                    You are editing {feeType}. Displayed current values.
                </h3>
            </div>

            {fees !== null && (
                <Form onSubmit={handleSubmit(handleSave)}>
                    {fees.map((fee: FeeI) => (
                        <div key={fee?._id}>
                            <div className="margin">
                                <span className="form-span">{fee?.name}</span>
                                <input
                                    {...register(fee.name, {
                                        required: true,
                                        setValueAs: (value) =>
                                            parseInt(value, 10),
                                    })}
                                    className="form-input"
                                    type="text"
                                    id={fee?._id}
                                    defaultValue={fee?.value}
                                />
                            </div>
                            {fee?.name !== 'Late Event Fee 2' && (
                                <div className="margin">
                                    <span className="form-span">
                                        {fee?.name} before
                                    </span>
                                    <select
                                        {...register(`${fee.name} Before`, {
                                            required: true,
                                            setValueAs: (
                                                value: string | number = '12 am'
                                            ) => {
                                                if (typeof value === 'number') {
                                                    return value;
                                                }

                                                return getMilitaryTime({
                                                    value: parseInt(
                                                        value.split(' ')[0],
                                                        10
                                                    ),
                                                    type: value.split(' ')[1],
                                                });
                                            },
                                        })}
                                        className="select-input"
                                        id={fee?._id}
                                        defaultValue={parseHour(
                                            fee.beforeHour ? fee.beforeHour : 0
                                        )}
                                    >
                                        <Options />
                                    </select>
                                </div>
                            )}
                        </div>
                    ))}
                    <button className="green-button margin">Save</button>
                    <button
                        className="red-button margin"
                        onClick={() =>
                            setAction({
                                status: false,
                                type: feeType,
                            })
                        }
                    >
                        Cancel
                    </button>
                </Form>
            )}
        </>
    );
};

export default DiscountForm;
