import { useEffect, useState } from 'react';
import User from '../interfaces/User';
import axios from 'axios';
import Action from '../interfaces/Action';

const useUsers = (setLoading: any, action: Action) => {
    const [users, setUsers] = useState<User[] | []>([]);

    useEffect(() => {
        (async () => {
            await setLoading(true);

            try {
                const { data } = await axios.get('/api/users');

                await setUsers(data);
            } catch (error) {
                console.log(error);
            } finally {
                await setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action]);

    return {
        users,
        setUsers,
    };
};

export default useUsers;
