import { MouseEvent } from 'react';

const Button = ({
    color,
    margin,
    children,
    onClick,
    id,
}: {
    color: string;
    margin: boolean;
    children: JSX.Element | string;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    id?: string;
}) => {
    return (
        <button
            className={margin ? `${color}-button margin` : `${color}-button`}
            id={id}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default Button;
