import axios from 'axios';

const isGuestChecked = async (guestID: string, type: string) => {
    try {
        const { data } = await axios.get(
            `/api/guests/single?guestID=${guestID.toLowerCase()}&type=${type}`
        );

        return data !== '';
    } catch (error) {
        console.log(error);
        return 'error';
    }
};

export default isGuestChecked;
