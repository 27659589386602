import Container from '../../components/Container';
import Header from '../../components/Header';
import GuestSignature from '../../components/GuestSignature';

const SignatureGuestPage = () => {
    return (
        <Container containerName={'landing'}>
            <Header title={'Signature'} subtitle={''} bottomBorder={true} padding={true}/>
            <GuestSignature />
        </Container>
    );
};

export default SignatureGuestPage;
