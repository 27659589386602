import { SubmitHandler, useForm } from 'react-hook-form';
import GuestI from '../interfaces/Guest';
import isGuestChecked from '../functions/isGuestChecked';
import getGuestByGuestID from '../functions/getGuestByGuestID';
import { useContext, useState } from 'react';
import GlobalContextI from '../interfaces/GlobalContext';
import GlobalContext from '../contexts/GlobalContext';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../components/Button';
import Form from '../components/Form';

type ScanForm = { scanID: string };

const CheckInForm = () => {
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<ScanForm>();
    const { setGuest } = useContext<GlobalContextI>(GlobalContext);
    const [message, setMessage] = useState('');
    const history = useHistory();
    const { type }: { type: string } = useParams();

    const handleContinue: SubmitHandler<ScanForm> = async ({ scanID }) => {
        const data: string[] = scanID.includes(',')
            ? scanID.split(',')
            : (scanID.split('<').filter((el) => el !== '') as string[]);
        let guest: GuestI;
        let lengthTest: boolean = true;
        const hour = new Date().getHours();
        const isReadyForParse =
            data.length === 13 ||
            data.length === 5 ||
            data.length === 6 ||
            data.length === 7 ||
            scanID.length === 6;

        if (hour >= 0 && hour < 11 && type === 'allAccess') {
            return setMessage(
                'Checking in has been disabled for today! Comeback tomorrow!'
            );
        }

        data.forEach((element: string) => {
            if (element.length >= 60) {
                lengthTest = false;
                return setMessage(
                    'Input from scanner is too long! Check if your device is correctly configured!'
                );
            }
        });

        if (!isReadyForParse || !lengthTest) {
            return;
        }

        if (scanID.length === 6) {
            const guestID = scanID;
            const guestStatus = await isGuestChecked(scanID, type);
            const guestData = await getGuestByGuestID(
                guestID.toLowerCase(),
                type
            );

            if (guestStatus === 'error') {
                return setMessage('API ERROR - CONTACT ADMIN!');
            }

            if (!guestStatus) {
                return setMessage(
                    `There's no guest with a Loyalty Number of ${guestID}, please scan your guest's ID or Enter the information manually!`
                );
            }

            await setGuest(guestData);

            return history.push(`/guests/checkin/${type}/${guestID}/update`);
        }

        if (data.length === 5) {
            guest = {
                city: '',
                dateOfBirth: '',
                eyes: '',
                guestID: '',
                height: '',
                licenseExpDate: '',
                middleName: '',
                sex: '',
                standing: '',
                state: '',
                streetAddress: '',
                weight: '',
                firstName: data[2],
                lastName: data[1].substring(3),
                licenseID: data[3],
            };

            const guestID =
                guest.firstName[0] +
                guest.lastName[0] +
                guest.licenseID.substr(guest.licenseID.length - 4);

            const oldGuestID =
                guest.firstName +
                guest.licenseID.substr(guest.licenseID.length - 4);

            const guestData = await getGuestByGuestID(
                guestID.toLowerCase(),
                type
            );
            const oldGuestData = await getGuestByGuestID(
                oldGuestID.toLowerCase(),
                type
            );

            if (
                guestData?.guestID === guestID.toLowerCase() ||
                oldGuestData?.guestID === oldGuestID.toLowerCase()
            ) {
                if (!guestData) {
                    await setGuest(oldGuestData);
                } else {
                    await setGuest(guestData);
                }

                return history.push(
                    `/guests/checkin/${type}/${guestID}/update`
                );
            }

            await setGuest(guest);

            return history.push(`/guests/checkin/create/guest/${type}/1}`);
        }

        if (data.length === 6) {
            guest = {
                city: '',
                dateOfBirth: '',
                eyes: '',
                guestID: '',
                height: '',
                licenseExpDate: '',
                middleName: data[3],
                sex: '',
                standing: '',
                state: '',
                streetAddress: '',
                weight: '',
                firstName: data[2],
                lastName: data[1].substring(3),
                licenseID: data[4],
            };

            const guestID =
                guest.firstName[0] +
                guest.lastName[0] +
                guest.licenseID.substr(guest.licenseID.length - 4);

            const oldGuestID =
                guest.firstName +
                guest.licenseID.substr(guest.licenseID.length - 4);

            const guestData = await getGuestByGuestID(
                guestID.toLowerCase(),
                type
            );
            const oldGuestData = await getGuestByGuestID(
                oldGuestID.toLowerCase(),
                type
            );

            if (
                guestData?.guestID === guestID.toLowerCase() ||
                oldGuestData?.guestID === oldGuestID.toLowerCase()
            ) {
                if (!guestData) {
                    await setGuest(oldGuestData);
                } else {
                    await setGuest(guestData);
                }

                return history.push(
                    `/guests/checkin/${type}/${guestID}/update`
                );
            }

            await setGuest(guest);

            return history.push(`/guests/checkin/create/guest/${type}/1}`);
        }

        if (data.length === 7) {
            guest = {
                city: '',
                dateOfBirth: '',
                eyes: '',
                guestID: '',
                height: '',
                licenseExpDate: '',
                middleName: data[4],
                sex: '',
                standing: '',
                state: '',
                streetAddress: '',
                weight: '',
                firstName: data[3],
                lastName: `${data[1].substring(3)} ${data[2]}`,
                licenseID: data[5],
            };

            const guestID =
                guest.firstName[0] +
                guest.lastName[0] +
                guest.licenseID.substr(guest.licenseID.length - 4);

            const oldGuestID =
                guest.firstName +
                guest.licenseID.substr(guest.licenseID.length - 4);

            const guestData = await getGuestByGuestID(
                guestID.toLowerCase(),
                type
            );
            const oldGuestData = await getGuestByGuestID(
                oldGuestID.toLowerCase(),
                type
            );

            if (
                guestData?.guestID === guestID.toLowerCase() ||
                oldGuestData?.guestID === oldGuestID.toLowerCase()
            ) {
                if (!guestData) {
                    await setGuest(oldGuestData);
                } else {
                    await setGuest(guestData);
                }

                return history.push(
                    `/guests/checkin/${type}/${guestID}/update`
                );
            }

            await setGuest(guest);

            return history.push(`/guests/checkin/create/guest/${type}/1}`);
        }

        const parseDate = (date: string) => {
            const dateSplit = date.split('-');

            if (dateSplit[2].length !== 2) {
                return date;
            }

            const getExpInt = () => {
                const expDate = new Date().getFullYear() + 8;

                return parseInt(`${expDate}`.split('20')[1]);
            };

            const year =
                parseInt(dateSplit[2]) >= getExpInt()
                    ? `19${dateSplit[2]}`
                    : `20${dateSplit[2]}`;
            const day =
                dateSplit[0].length === 1 ? `0${dateSplit[0]}` : dateSplit[0];
            const month =
                dateSplit[1].length === 1 ? `0${dateSplit[1]}` : dateSplit[1];

            return `${day}-${month}-${year}`;
        };

        guest = {
            firstName: data[0],
            middleName: data[1],
            lastName: data[2],
            dateOfBirth: parseDate(data[3].split(' ')[0].replaceAll('/', '-')),
            streetAddress: data[4],
            city: data[5],
            state: data[6],
            licenseID: data[7],
            licenseExpDate: parseDate(
                data[8].split(' ')[0].replaceAll('/', '-')
            ),
            sex: data[9],
            eyes: data[10],
            height: data[11],
            weight: data[12],
            standing: 'GOOD',
            type: type,
            entryDates: [],
            guestID: '',
        };

        const guestID =
            guest.firstName[0] +
            guest.lastName[0] +
            guest.licenseID.substr(guest.licenseID.length - 4);

        const oldGuestID =
            guest.firstName +
            guest.licenseID.substr(guest.licenseID.length - 4);

        const guestData = await getGuestByGuestID(guestID.toLowerCase(), type);
        const oldGuestData = await getGuestByGuestID(
            oldGuestID.toLowerCase(),
            type
        );

        if (
            guestData?.guestID === guestID.toLowerCase() ||
            oldGuestData?.guestID === oldGuestID.toLowerCase()
        ) {
            if (!guestData) {
                await setGuest(oldGuestData);
            } else {
                await setGuest(guestData);
            }

            return history.push(`/guests/checkin/${type}/${guestID}/update`);
        }

        await setGuest(guest);

        return history.push(`/guests/checkin/create/guest/${type}/1}`);
    };

    return (
        <Form onSubmit={handleSubmit(handleContinue)}>
            {message !== '' && (
                <div className="landing-container__text">
                    <p>{message}</p>
                </div>
            )}
            <div>
                <span className="form-span">Scanner Input</span>
                {errors.scanID?.type === 'required' && (
                    <p className="landing-container__warning">
                        This field is required!
                    </p>
                )}
                <input
                    {...register('scanID', { required: true })}
                    type="text"
                    placeholder={
                        type === 'limited'
                            ? "Scan Guest's ID"
                            : 'Scan ID or enter Loyalty Number...'
                    }
                    className="form-input"
                    autoFocus
                />
            </div>
            <Button color={'green'} margin={true}>
                Continue
            </Button>
            <Button
                color={type}
                margin={true}
                onClick={() => {
                    setGuest(null);
                    return history.push(
                        `/guests/checkin/create/guest/${type}/1}`
                    );
                }}
            >
                Manual Check-In
            </Button>
            <Button
                color={'red'}
                margin={true}
                onClick={() => history.push(`/dashboard`)}
            >
                Go Back
            </Button>
        </Form>
    );
};

export default CheckInForm;
