import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import TransactionForm from '../../forms/TransactionForm';
import TransactionStatistics from '../../components/TransactionStatistics';
import TransactionI from '../../interfaces/Transaction';
import Action from '../../interfaces/Action';
import ParamTypes from '../../interfaces/ParamTypes';
import TransactionsI from '../../interfaces/Transactions';
import useTransactions from '../../hooks/useTransactions';
import Container from '../../components/Container';
import Header from '../../components/Header';
import Transactions from '../../components/Transactions';

const TransactionPage = () => {
    const { date }: ParamTypes = useParams();
    const [action, setAction] = useState<Action>({
        status: false,
        type: 'create',
    });
    const [loading, setLoading] = useState<boolean>(true);
    const { transactions } = useTransactions(action, date, setLoading);
    const [transaction, setTransaction] = useState<TransactionI | null>(null);

    if (loading) {
        return <Loading loading={loading} />;
    }

    if (action.status) {
        if (action.type === 'statistics') {
            return (
                <Container containerName={'landing'}>
                    <Header
                        title={'Viewing: Statistics'}
                        subtitle={''}
                        bottomBorder={true}
                        padding={true}
                    />
                    <TransactionStatistics
                        setAction={setAction}
                        date={date}
                        transactions={transactions as TransactionsI}
                    />
                </Container>
            );
        }

        if (action.type === 'edit') {
            return (
                <Container containerName={'landing'}>
                    <Header
                        title={'Edit Transaction'}
                        subtitle={''}
                        bottomBorder={true}
                    />
                    <TransactionForm
                        transaction={transaction}
                        setAction={setAction}
                        setLoading={setLoading}
                    />
                </Container>
            );
        }

        return <></>;
    }

    return (
        <Container containerName={'landing'}>
            <Header
                title={'Viewing Transactions'}
                subtitle={''}
                bottomBorder={true}
                padding={true}
            />
            <Transactions
                transactions={transactions}
                transaction={transaction}
                date={date}
                setTransaction={setTransaction}
                setLoading={setLoading}
                setAction={setAction}
            />
        </Container>
    );
};

export default TransactionPage;
