import GuestI from '../interfaces/Guest';

const sortGuests = (type: string, filterType: string, guests: GuestI[]) =>
    guests.sort((a: GuestI, b: GuestI) => {
        if (filterType === 'date') {
            return 0;
        }

        if (a.entryDates === undefined || b.entryDates === undefined) {
            return 0;
        }

        if (type === 'limited') {
            if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
                return 1;
            }

            if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
                return -1;
            }

            if (a.lastName.toLowerCase() === b.lastName.toLowerCase()) {
                const aRecentEntry = new Date(
                    a.entryDates[a.entryDates.length - 1]
                );
                const bRecentEntry = new Date(
                    b.entryDates[b.entryDates.length - 1]
                );

                if (aRecentEntry.getTime() > bRecentEntry.getTime()) {
                    return 1;
                }

                if (bRecentEntry.getTime() > aRecentEntry.getTime()) {
                    return -1;
                }
            }
        }

        if (type === 'allAccess') {
            if (a.stageName === undefined || b.stageName === undefined) {
                return 0;
            }

            if (a.stageName.toLowerCase() > b.stageName.toLowerCase()) {
                return 1;
            }

            if (a.stageName.toLowerCase() < b.stageName.toLowerCase()) {
                return -1;
            }

            if (a.stageName.toLowerCase() === b.stageName.toLowerCase()) {
                const aRecentEntry = new Date(
                    a.entryDates[a.entryDates.length - 1]
                );
                const bRecentEntry = new Date(
                    b.entryDates[b.entryDates.length - 1]
                );

                if (aRecentEntry.getTime() > bRecentEntry.getTime()) {
                    return 1;
                }

                if (bRecentEntry.getTime() > aRecentEntry.getTime()) {
                    return -1;
                }
            }
        }

        return 0;
    });

export default sortGuests;
