import GuestI from '../interfaces/Guest';
import AllAccessGuestI from '../interfaces/AllAccessGuest';
import { useEffect, useState } from 'react';
import TransactionI from '../interfaces/Transaction';
import axios from 'axios';
import parseDate from '../functions/parseDate';

const useTransaction = (
    { guestID }: GuestI | AllAccessGuestI,
    setLoading: any
) => {
    const [transaction, setTransaction] = useState<TransactionI | null>(null);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const { data } = await axios.get(
                    `/api/transactions?type=lastTransaction&date=${parseDate()}&guestID=${guestID.toLowerCase()}`
                );

                await setTransaction(data);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        transaction,
        setTransaction,
    };
};

export default useTransaction;
