import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Loading from '../components/Loading';
import GuestFormProps from '../interfaces/GuestFormProps';
import ParamTypes from '../interfaces/ParamTypes';
import GlobalContextI from '../interfaces/GlobalContext';
import GlobalContext from '../contexts/GlobalContext';
import AllAccessGuestI from '../interfaces/AllAccessGuest';
import GuestI from '../interfaces/Guest';
import getAgreement from '../functions/getAgreement';
import EntryDates from '../components/EntryDates';
import { SubmitHandler, useForm } from 'react-hook-form';
import updateGuest from '../functions/updateGuest';
import getGuestID from '../functions/getGuestID';
import goBack from '../functions/goBack';
import validator from 'validator';
import useDiscount from '../hooks/useDiscount';
import Form from '../components/Form';

const GuestForm = ({ action }: GuestFormProps) => {
    const { user, guest, setGuest } = useContext<GlobalContextI>(GlobalContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [isNotModified, setIsNotModified] = useState<boolean>(true);
    const { discount, loadingDiscount } = useDiscount(
        action,
        guest?.points || 0
    );
    const history = useHistory();
    const { type }: ParamTypes = useParams();
    const {
        register,
        formState: { errors },
        handleSubmit,
        getValues,
    } = useForm<GuestI | AllAccessGuestI>();

    const handleGuest: SubmitHandler<GuestI | AllAccessGuestI> = async (
        data
    ) => {
        if (action === 'add') {
            setGuest({
                ...data,
                guestID: getGuestID({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    licenseID: data.licenseID,
                }).toLowerCase(),
                type: type,
            });

            return history.push(`/guests/notice`);
        }

        if (action === 'edit') {
            if (guest.type === 'allAccess') {
                data.active = data.active === 'active';
            }

            return await updateGuest(guest, data, setLoading, history);
        }
    };

    const handleAgreement = async (e: any) => {
        const id = e.target.value;

        if (id === 'default') {
            return;
        }

        return await getAgreement(guest, user, setLoading, parseInt(id, 10));
    };

    const handleGoBack = (e: any) => {
        e.preventDefault();

        return goBack(guest, getValues(), setIsNotModified, history);
    };

    if (loading || loadingDiscount) {
        return <Loading loading={loading} />;
    }

    return (
        <>
            {!isNotModified && (
                <div className="new-guest-container__text">
                    <p>
                        You have modified guest's profile please press continue
                        to save changes or continue without saving button.
                    </p>

                    <button
                        className="yellow-button margin-guest"
                        onClick={() => history.push(guest?.page as string)}
                    >
                        Continue without saving
                    </button>
                </div>
            )}

            <Form onSubmit={handleSubmit(handleGuest)}>
                <button className="green-button margin-guest">Continue</button>
                {action === 'edit' && (
                    <div>
                        <span className="form-span">Entries: </span>
                        <select
                            className="form-input"
                            onChange={handleAgreement}
                        >
                            <option value="default">
                                Choose entry to download a file
                            </option>
                            {guest?.entryDates && (
                                <EntryDates entryDates={guest?.entryDates} />
                            )}
                        </select>
                    </div>
                )}
                {type === 'allAccess' && (
                    <>
                        <div>
                            <span className="form-span">*Stage Name: </span>

                            {errors.stageName?.type === 'required' && (
                                <p className="landing-container__warning">
                                    This field is required
                                </p>
                            )}
                            {errors.stageName?.type === 'maxLength' && (
                                <p className="landing-container__warning">
                                    This field is too long
                                </p>
                            )}
                            <input
                                {...register('stageName', {
                                    required: true,
                                    maxLength: 64,
                                })}
                                type="text"
                                className="form-input"
                                placeholder="enter stage name..."
                                defaultValue={guest?.stageName || ''}
                            />
                        </div>
                        {action === 'edit' && (
                            <div>
                                <span className="form-span">
                                    *Account Status:{' '}
                                </span>
                                <select
                                    {...register('active')}
                                    className="form-input"
                                    defaultValue={
                                        guest.active ? 'active' : 'inactive'
                                    }
                                >
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </select>
                            </div>
                        )}
                        <div>
                            <span className="form-span">*Loyalty Points: </span>
                            {errors.points?.type === 'maxLength' && (
                                <p className="landing-container__warning">
                                    This field is too long
                                </p>
                            )}
                            <input
                                {...register('points', {
                                    maxLength: 32,
                                    setValueAs: (value) => parseInt(value, 10),
                                })}
                                type="text"
                                className="form-input"
                                placeholder="enter points..."
                                defaultValue={guest?.points || '0'}
                            />
                        </div>
                        <div>
                            <span className="form-span">*Additional fee: </span>

                            {errors.discount?.type === 'maxLength' && (
                                <p className="landing-container__warning">
                                    This field is too long
                                </p>
                            )}

                            <input
                                {...register('discount', {
                                    maxLength: 32,
                                    setValueAs: (value) => parseInt(value, 10),
                                })}
                                type="text"
                                className="form-input"
                                placeholder="enter Discount..."
                                defaultValue={0}
                            />
                        </div>
                    </>
                )}
                <div>
                    <span className="form-span">First Name: </span>
                    {errors.firstName?.type === 'required' && (
                        <p className="landing-container__warning">
                            This field is required
                        </p>
                    )}
                    {errors.firstName?.type === 'maxLength' && (
                        <p className="landing-container__warning">
                            This field is too long
                        </p>
                    )}
                    <input
                        {...register('firstName', {
                            required: true,
                            maxLength: 64,
                        })}
                        type="text"
                        className="form-input"
                        placeholder="enter first name..."
                        defaultValue={guest?.firstName || ''}
                    />
                </div>
                <div>
                    <span className="form-span">Middle Name: </span>
                    {errors.middleName?.type === 'required' && (
                        <p className="landing-container__warning">
                            This field is required
                        </p>
                    )}
                    {errors.middleName?.type === 'maxLength' && (
                        <p className="landing-container__warning">
                            This field is too long
                        </p>
                    )}
                    <input
                        {...register('middleName', { maxLength: 32 })}
                        type="text"
                        className="form-input"
                        placeholder="enter middle name..."
                        defaultValue={guest?.middleName}
                    />
                </div>
                <div>
                    <span className="form-span">Last Name: </span>
                    {errors.lastName?.type === 'required' && (
                        <p className="landing-container__warning">
                            This field is required
                        </p>
                    )}
                    {errors.lastName?.type === 'maxLength' && (
                        <p className="landing-container__warning">
                            This field is too long
                        </p>
                    )}
                    <input
                        {...register('lastName', {
                            required: true,
                            maxLength: 64,
                        })}
                        type="text"
                        className="form-input"
                        placeholder="enter last name..."
                        defaultValue={guest?.lastName || ''}
                    />
                </div>
                <div>
                    <span className="form-span">
                        Date of Birth(eg. MONTH-DAY-YEAR):{' '}
                    </span>
                    {errors.dateOfBirth?.type === 'required' && (
                        <p className="landing-container__warning">
                            This field is required
                        </p>
                    )}
                    {errors.dateOfBirth?.type === 'maxLength' && (
                        <p className="landing-container__warning">
                            This field is too long
                        </p>
                    )}
                    <input
                        {...register('dateOfBirth', {
                            required: true,
                            maxLength: 32,
                        })}
                        type="text"
                        className="form-input"
                        placeholder="enter date of birth..."
                        defaultValue={(guest?.dateOfBirth as string) || ''}
                    />
                </div>
                <div>
                    <span className="form-span">Street Address: </span>
                    {errors.streetAddress?.type === 'maxLength' && (
                        <p className="landing-container__warning">
                            This field is too long
                        </p>
                    )}
                    <input
                        {...register('streetAddress', { maxLength: 32 })}
                        type="text"
                        className="form-input"
                        placeholder="enter street address..."
                        defaultValue={guest?.streetAddress || ''}
                    />
                </div>
                <div>
                    <span className="form-span">City: </span>
                    {errors.city?.type === 'maxLength' && (
                        <p className="landing-container__warning">
                            This field is too long
                        </p>
                    )}
                    <input
                        {...register('city', { maxLength: 32 })}
                        type="text"
                        className="form-input"
                        placeholder="enter city..."
                        defaultValue={guest?.city || ''}
                    />
                </div>
                <div>
                    <span className="form-span">State: </span>
                    {errors.state?.type === 'maxLength' && (
                        <p className="landing-container__warning">
                            This field is too long
                        </p>
                    )}
                    <input
                        {...register('state', { maxLength: 32 })}
                        type="text"
                        className="form-input"
                        placeholder="enter state..."
                        defaultValue={guest?.state}
                    />
                </div>
                <div>
                    <span className="form-span">License #: </span>
                    {errors.licenseID?.type === 'maxLength' && (
                        <p className="landing-container__warning">
                            This field is too long
                        </p>
                    )}
                    <input
                        {...register('licenseID', {
                            required: true,
                            maxLength: 64,
                        })}
                        type="text"
                        className="form-input"
                        placeholder="enter driver license number..."
                        defaultValue={guest?.licenseID || ''}
                    />
                </div>
                <div>
                    <span className="form-span">Expiration Date: </span>
                    {errors.licenseExpDate?.type === 'maxLength' && (
                        <p className="landing-container__warning">
                            This field is too long
                        </p>
                    )}
                    <input
                        {...register('licenseExpDate', { maxLength: 32 })}
                        type="text"
                        className="form-input"
                        placeholder="enter driver license expiration date..."
                        defaultValue={guest?.licenseExpDate || ''}
                    />
                </div>
                <div>
                    <span className="form-span">Sex: </span>
                    {errors.sex?.type === 'maxLength' && (
                        <p className="landing-container__warning">
                            This field is too long
                        </p>
                    )}
                    <select
                        {...register('sex', { maxLength: 5 })}
                        className="form-input"
                        defaultValue={guest?.sex || 'M'}
                    >
                        <option value="M">M</option>
                        <option value="F">F</option>
                    </select>
                </div>
                <div>
                    <span className="form-span">Eyes: </span>
                    {errors.eyes?.type === 'maxLength' && (
                        <p className="landing-container__warning">
                            This field is too long
                        </p>
                    )}
                    <input
                        {...register('eyes', { maxLength: 32 })}
                        type="text"
                        className="form-input"
                        placeholder="enter eye color..."
                        defaultValue={guest?.eyes || ''}
                    />
                </div>
                <div>
                    <span className="form-span">Height: </span>
                    {errors.height?.type === 'maxLength' && (
                        <p className="landing-container__warning">
                            This field is too long
                        </p>
                    )}
                    <input
                        {...register('height', { maxLength: 3 })}
                        type="text"
                        className="form-input"
                        placeholder="enter height..."
                        defaultValue={guest?.height || ''}
                    />
                </div>
                <div>
                    <span className="form-span">Weight: </span>
                    {errors.weight?.type === 'maxLength' && (
                        <p className="landing-container__warning">
                            This field is too long
                        </p>
                    )}
                    <input
                        {...register('weight', {
                            maxLength: 3,
                        })}
                        type="text"
                        className="form-input"
                        placeholder="enter weight..."
                        defaultValue={guest?.weight || ''}
                    />
                </div>
                <div>
                    <span className="form-span">Phone Number: </span>
                    {errors.phoneNumber?.type === 'maxLength' && (
                        <p className="landing-container__warning">
                            This field is too long
                        </p>
                    )}
                    <input
                        {...register('phoneNumber', { maxLength: 12 })}
                        type="text"
                        className="form-input"
                        placeholder="enter phone number..."
                        defaultValue={guest?.phoneNumber || ''}
                    />
                </div>
                <div>
                    <span className="form-span">Email Address: </span>
                    {errors.email?.type === 'maxLength' && (
                        <p className="landing-container__warning">
                            This field is too long
                        </p>
                    )}
                    {errors.email?.type === 'validate' && (
                        <p className="landing-container__warning">
                            This field is not an email
                        </p>
                    )}
                    <input
                        {...register('email', {
                            maxLength: 55,
                            validate: (value) =>
                                validator.isEmail(value!) || value === '',
                        })}
                        type="email"
                        className="form-input"
                        placeholder="enter email address..."
                        defaultValue={guest?.email || ''}
                    />
                </div>
                {action === 'edit' && (
                    <>
                        <div>
                            <span className="form-span">
                                Loyalty Rewards Number:{' '}
                            </span>
                            <input
                                type="email"
                                className="form-input"
                                disabled
                                defaultValue={guest?.guestID || ''}
                            />
                        </div>
                        <div>
                            <span className="form-span">Standing: </span>
                            {errors.standing?.type === 'maxLength' && (
                                <p className="landing-container__warning">
                                    This field is too long
                                </p>
                            )}
                            <select
                                {...register('standing', { maxLength: 4 })}
                                className="form-input"
                                defaultValue={guest?.standing || 'GOOD'}
                            >
                                <option value="GOOD">GOOD</option>
                                <option value="BAD">BAD</option>
                            </select>
                        </div>
                        <div>
                            <span className="form-span">ToS: </span>
                            {errors.tos?.type === 'maxLength' && (
                                <p className="landing-container__warning">
                                    This field is too long
                                </p>
                            )}
                            <select
                                {...register('tos', {
                                    maxLength: 5,
                                    setValueAs: (value: string) =>
                                        JSON.parse(value),
                                })}
                                className="form-input"
                                defaultValue={guest?.tos ? 'true' : 'false'}
                            >
                                <option value="false">false</option>
                                <option value="true">true</option>
                            </select>
                        </div>
                        <div>
                            <span className="form-span">Lease: </span>
                            {errors.lease?.type === 'maxLength' && (
                                <p className="landing-container__warning">
                                    This field is too long
                                </p>
                            )}
                            <select
                                {...register('lease', {
                                    maxLength: 5,
                                    setValueAs: (value: string) =>
                                        JSON.parse(value),
                                })}
                                className="form-input"
                                defaultValue={guest?.lease ? 'true' : 'false'}
                            >
                                <option value="false">false</option>
                                <option value="true">true</option>
                            </select>
                        </div>
                    </>
                )}
                {action === 'edit' && (
                    <button
                        className="red-button margin"
                        onClick={handleGoBack}
                    >
                        Go back
                    </button>
                )}
            </Form>
        </>
    );
};

export default GuestForm;
