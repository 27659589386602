import TransactionI from '../interfaces/Transaction';
import getHour from '../functions/getHour';
import EditIcon from '../img/edit.svg';
import DeleteIcon from '../img/delete.svg';
import TransactionTableI from '../interfaces/TransactionTable';

const TransactionTable = ({
    transactions,
    handleEdit,
    handleEditTransaction,
    openModal,
    allAccess = false,
}: TransactionTableI) => {
    if (!transactions) {
        return <></>;
    }

    return (
        <table className="transaction-container__form--table">
            <thead>
                <tr>
                    <th>{allAccess ? 'Stage' : 'Name'}</th>
                    <th>Check-In Time</th>
                    <th>Paid</th>
                    {allAccess && <th>Points</th>}
                    <th>Edit</th>
                    <th>Delete</th>
                </tr>
            </thead>
            <tbody>
                {transactions.map(
                    ({
                        _id,
                        stageName,
                        firstName,
                        lastName,
                        createdAt,
                        value,
                        points,
                    }: TransactionI) => (
                        <tr id={_id} key={_id}>
                            <td
                                onClick={handleEdit}
                                className="transaction-container__form--table-stage"
                            >
                                {allAccess
                                    ? stageName.toUpperCase()
                                    : `${firstName.toUpperCase()} ${lastName.toUpperCase()}`}
                            </td>
                            <td className="transaction-container__form--table-time">
                                {getHour(createdAt)}
                            </td>
                            <td className="transaction-container__form--table-value">
                                {value}$
                            </td>
                            {allAccess && (
                                <td className="transaction-container__form--table-value">
                                    {points}
                                </td>
                            )}
                            <td className="transaction-container__form--table-value">
                                <button
                                    className="user-button-icon"
                                    onClick={handleEditTransaction}
                                >
                                    <img
                                        className={_id}
                                        src={EditIcon}
                                        alt="edit Icon"
                                    />
                                </button>
                            </td>
                            <td className="transaction-container__form--table-value">
                                <button
                                    className="user-button-icon"
                                    onClick={openModal}
                                >
                                    <img
                                        className={_id}
                                        src={DeleteIcon}
                                        alt="delete Icon"
                                    />
                                </button>
                            </td>
                        </tr>
                    )
                )}
            </tbody>
        </table>
    );
};

export default TransactionTable;
