const Header = ({
    title,
    subtitle,
    size,
    bottomBorder,
    padding
}: {
    title: string;
    subtitle: string;
    size?: string;
    bottomBorder?: boolean;
    padding?: boolean;
}) => {
    const headerName = padding ? 'landing-container__header-login' : 'landing-container__header'
    const containerName = bottomBorder
        ? `${headerName}--h1-bottom`
        : `${headerName}--h1`;

    return (
        <header className={headerName}>
            <h1
                className={
                    size === 'big' ? `${containerName}-big` : containerName
                }
            >
                {title}
            </h1>
            {subtitle.length > 1 && (
                <h3 className="landing-container__header--h3">{subtitle}</h3>
            )}
        </header>
    );
};

export default Header;
