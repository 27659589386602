import { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import GlobalContext from '../contexts/GlobalContext';
import GlobalContextI from '../interfaces/GlobalContext';
import Container from '../components/Container';
import Header from '../components/Header';
import LoginForm from '../forms/LoginForm';

const LandingPage = () => {
    const { user, setUser } = useContext<GlobalContextI>(GlobalContext);

    if (user) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <Container containerName={'landing'}>
            <Header title={'Log In'} subtitle={'Use credentials provided'} padding={true}/>
            <LoginForm setUser={setUser} />
        </Container>
    );
};

export default LandingPage;
