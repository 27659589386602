import Modal from 'react-modal';
import { MouseEvent } from 'react';
import deleteTransaction from '../../functions/deleteTransaction';
import TransactionI from '../../interfaces/Transaction';
import Action from '../../interfaces/Action';
import Button from '../Button';

const TransactionModal = ({
    transaction,
    isOpen,
    setIsOpen,
    setLoading,
    setAction,
}: {
    transaction: TransactionI | null;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    setLoading: (value: boolean) => void;
    setAction: (action: Action) => void;
}) => {
    const handleDelete = async (e: MouseEvent<HTMLButtonElement>) => {
        const id = e.currentTarget.id;

        setLoading(true);
        setIsOpen(false);

        await deleteTransaction(id, setLoading, setAction);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            ariaHideApp={false}
            className="modal"
        >
            <header className="modal__header">
                <h1 className="modal__header--h1">
                    Do you want to delete transaction:{' '}
                    <span className="green-span">{transaction?._id}</span>
                </h1>
            </header>

            <Button
                color={'green'}
                margin={true}
                id={transaction?._id}
                onClick={handleDelete}
            >
                Yes
            </Button>

            <Button
                color={'red'}
                margin={true}
                onClick={() => setIsOpen(false)}
            >
                No
            </Button>
        </Modal>
    );
};

export default TransactionModal;
