import { useHistory } from 'react-router-dom';
import UsersIcon from '../img/users.svg';
import FeesIcon from '../img/fees.svg';
import TransactionIcon from '../img/transaction.svg';
import AllAccessCheckIn from '../img/allaccesscheckin.svg';
import CheckInIcon from '../img/checkin.svg';
import DatabaseIcon from '../img/database.svg';

const DashboardPanel = ({ accessLevel }: { accessLevel: number }) => {
    const history = useHistory();

    return (
        <div>
            <div className="button-container">
                {accessLevel >= 3 && (
                    <>
                        <label className="label-block">
                            <button
                                className="label-block__button"
                                onClick={() => history.push('/users')}
                            >
                                <img
                                    className="button-icon"
                                    src={UsersIcon}
                                    alt="Users Icon"
                                />
                            </button>
                            Users
                        </label>
                        <label className="label-block">
                            <button
                                className="label-block__button"
                                onClick={() => history.push('/fees')}
                            >
                                <img
                                    className="button-icon"
                                    src={FeesIcon}
                                    alt="Fees Icon"
                                />
                            </button>
                            Fees
                        </label>
                        <label className="label-block">
                            <button
                                className="label-block__button"
                                onClick={() => history.push('/transactions')}
                            >
                                <img
                                    className="button-icon"
                                    src={TransactionIcon}
                                    alt="Transaction Icon"
                                />
                            </button>
                            Transaction Lookup
                        </label>
                    </>
                )}
            </div>
            <div className="button-container">
                {accessLevel === 2 && (
                    <>
                        <label className="label-block">
                            <button
                                className="label-block__button"
                                onClick={() => history.push('/transactions')}
                            >
                                <img
                                    className="button-icon"
                                    src={TransactionIcon}
                                    alt="Transaction Icon"
                                />
                            </button>
                            Transaction Lookup
                        </label>
                    </>
                )}
                {accessLevel >= 2 && (
                    <>
                        <label className="label-block">
                            <button
                                className="label-block__button"
                                onClick={() =>
                                    history.push('/guests/checkin/allAccess')
                                }
                            >
                                <img
                                    className="button-icon"
                                    src={AllAccessCheckIn}
                                    alt="All Access guest Icon"
                                />
                            </button>
                            All-Access Guest
                        </label>
                    </>
                )}
                {accessLevel >= 1 && (
                    <>
                        <label className="label-block">
                            <button
                                className="label-block__button"
                                onClick={() =>
                                    history.push('/guests/checkin/limited')
                                }
                            >
                                <img
                                    className="button-icon"
                                    src={CheckInIcon}
                                    alt="Limited guest Icon"
                                />
                            </button>
                            Guest Check-In
                        </label>
                        <label className="label-block">
                            <button
                                className="label-block__button"
                                onClick={() => history.push('/guests')}
                            >
                                <img
                                    className="button-icon"
                                    src={DatabaseIcon}
                                    alt="Database Icon"
                                />
                            </button>
                            Guest Database
                        </label>
                    </>
                )}
            </div>
        </div>
    );
};

export default DashboardPanel;
