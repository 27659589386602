import FiltersI from '../interfaces/Filters';

const Filters = ({
    filter,
    dateInput,
    ageInput,
    setInput,
    setAgeInput,
    setDateInput,
}: FiltersI) => {
    switch (filter) {
        case 'guestID': {
            return (
                <>
                    <span className="form-span">Filter guests by guestID:</span>
                    <input
                        className="form-input"
                        type="text"
                        onChange={(e) => setInput(e.target.value.toLowerCase())}
                    />
                </>
            );
        }
        case 'keyword': {
            return (
                <>
                    <span className="form-span">Filter guests by keyword:</span>
                    <input
                        className="form-input"
                        type="text"
                        onChange={(e) => setInput(e.target.value.toLowerCase())}
                    />
                </>
            );
        }
        case 'age': {
            return (
                <>
                    <span className="form-span">
                        Filter guests by age range:
                    </span>
                    <input
                        className="form-input"
                        type="number"
                        onChange={(e) =>
                            setAgeInput({
                                ...ageInput,
                                age1: parseInt(e.target.value),
                            })
                        }
                    />
                    <input
                        className="form-input margin"
                        type="number"
                        onChange={(e) =>
                            setAgeInput({
                                ...ageInput,
                                age2: parseInt(e.target.value),
                            })
                        }
                    />
                </>
            );
        }
        case 'stageName': {
            return (
                <>
                    <span className="form-span">
                        Filter guests by stage name:
                    </span>
                    <input
                        className="form-input"
                        type="text"
                        onChange={(e) => setInput(e.target.value.toLowerCase())}
                    />
                </>
            );
        }
        case 'fullName': {
            return (
                <>
                    <span className="form-span">
                        Filter guests by full name:
                    </span>
                    <input
                        className="form-input"
                        type="text"
                        onChange={(e) => setInput(e.target.value.toLowerCase())}
                    />
                </>
            );
        }
        case 'date': {
            return (
                <>
                    <span className="form-span">Filter guests by date:</span>
                    <input
                        className="form-input"
                        type="date"
                        onChange={(e) =>
                            setDateInput({
                                ...dateInput,
                                date1: e.target.value,
                            })
                        }
                    />
                    <input
                        className="form-input margin"
                        type="date"
                        onChange={(e) =>
                            setDateInput({
                                ...dateInput,
                                date2: e.target.value,
                            })
                        }
                    />
                </>
            );
        }

        default: {
            return <></>;
        }
    }
};

export default Filters;
