const getPoints = (points: number, guestStatus: boolean) => {
    if (!guestStatus) {
        return 0;
    }

    if (new Date().getDay() === 0 && new Date().getHours() >= 11) {
        return points + 2;
    }

    return points + 1;
};

export default getPoints;
