import GlobalContext from '../contexts/GlobalContext';
import { Route, Redirect } from 'react-router-dom';
import Loading from '../components/Loading';
import { useContext } from 'react';

const PrivateRoute = ({ children, ...rest }: any) => {
    const { user, isLoading } = useContext(GlobalContext);

    if (isLoading) {
        return (
            <>
                <Loading loading={isLoading} />
            </>
        );
    }

    return <Route {...rest}>{user ? children : <Redirect to="/" />}</Route>;
};

export default PrivateRoute;
