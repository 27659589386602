const FilterUsers = ({ setInput }: { setInput: (value: string) => void }) => (
    <div>
        <span className="form-span">Filter users:</span>
        <input
            className="form-input"
            type="text"
            onChange={(e) => setInput(e.target.value)}
        />
    </div>
);

export default FilterUsers;
