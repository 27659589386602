import TransactionI from '../interfaces/Transaction';
import getMilitaryTime from './getMilitaryTime';
import TransactionsI from '../interfaces/Transactions';

const getSortedTransactions = (
    transactions: TransactionsI,
    time: { value: number; type: string }
) => {
    const transactionsBefore: TransactionI[] = [];
    const transactionsAfter: TransactionI[] = [];

    transactions.limited.forEach((transaction: TransactionI) => {
        const transactionHour = new Date(transaction.createdAt).getHours();

        if (transactionHour < getMilitaryTime(time)) {
            return transactionsBefore.push(transaction);
        }

        return transactionsAfter.push(transaction);
    });

    return { transactionsBefore, transactionsAfter };
};

export default getSortedTransactions;
