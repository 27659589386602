import GuestI from '../interfaces/Guest';
import Action from '../interfaces/Action';
import getGuest from './getGuest';
import axios from 'axios';

const deleteGuest = async (
    id: string,
    guests: GuestI[],
    setLoading: (logic: boolean) => void,
    setIsOpen: (logic: boolean) => void,
    setAction: (action: Action) => void
) => {
    await setLoading(true);

    try {
        const { _id, type, guestID } = getGuest(id, guests) as GuestI;

        await axios.delete(`/api/guests?id=${_id}&type=${type}`);

        await axios.delete(`/api/signatures/${guestID}`);
    } catch (error) {
        console.log(error);
    } finally {
        await setIsOpen(false);
        await setLoading(false);
        await setAction({ status: false, type: 'delete' });
    }
};

export default deleteGuest;
