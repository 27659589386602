import { useEffect, useState } from 'react';
import TransactionsI from '../interfaces/Transactions';
import axios from 'axios';
import TransactionI from '../interfaces/Transaction';
import Action from '../interfaces/Action';

const useTransactions = (
    action: Action,
    date: string,
    setLoading: (state: boolean) => void
) => {
    const [transactions, setTransactions] = useState<TransactionsI | null>(
        null
    );

    useEffect(() => {
        (async () => {
            await setLoading(true);

            try {
                const { data } = await axios.get(
                    `/api/transactions?date=${date}`
                );

                if (data.length < 1) {
                    return;
                }

                const allAccessTransactions = data.filter(
                    (transaction: TransactionI) =>
                        transaction.type === 'allAccess'
                );
                const limitedTransactions = data.filter(
                    (transaction: TransactionI) =>
                        transaction.type === 'limited'
                );

                await setTransactions({
                    allAccess: allAccessTransactions,
                    limited: limitedTransactions,
                });
            } catch (error) {
                console.log(error);
            } finally {
                await setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action]);

    return {
        transactions,
        setTransactions,
    };
};

export default useTransactions;
