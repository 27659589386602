import UserList from './UserList';
import User from '../interfaces/User';
import { MouseEvent } from 'react';

const UserTable = ({
    users,
    input,
    handleEdit,
    openModal,
}: {
    users: User[];
    input: string;
    handleEdit: (e: MouseEvent<HTMLButtonElement>) => void;
    openModal: (e: MouseEvent<HTMLButtonElement>) => void;
}) => {
    return (
        <table className="user-container__form--table">
            <thead />
            <tbody>
                <UserList
                    users={users}
                    input={input}
                    handleEdit={handleEdit}
                    openModal={openModal}
                />
            </tbody>
        </table>
    );
};

export default UserTable;
