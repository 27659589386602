import axios from 'axios';
import Action from '../interfaces/Action';

const deleteTransaction = async (
    id: string,
    setLoading: (logic: boolean) => void,
    setAction: (action: Action) => void
) => {
    try {
        await axios.delete(`/api/transactions?id=${id}`);
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
        setAction({ status: false, type: 'delete' });
    }
};

export default deleteTransaction;
