import { useContext, useState } from 'react';
import Loading from '../../components/Loading';
import GlobalContext from '../../contexts/GlobalContext';
import GlobalContextI from '../../interfaces/GlobalContext';
import useFee from '../../hooks/useFee';
import Header from '../../components/Header';
import Container from '../../components/Container';
import NoticeGuestConfirm from '../../components/NoticeGuestConfirm';
import NoticeGuestModal from '../../components/modals/NoticeGuestModal';

const NoticeGuestPage = () => {
    const { guest, setGuest } = useContext<GlobalContextI>(GlobalContext);
    const [isLoading, setLoading] = useState(false);
    const [day] = useState(new Date().getDay());
    const [today] = useState(
        new Date().toLocaleDateString('en-US', {
            weekday: 'long',
        })
    );
    const [feeType] = useState(
        guest?.type === 'allAccess' ? 'AAEventFee' : 'EventFee'
    );
    const { fee, setFee, loadingFee } = useFee(feeType, guest, day, today);
    const [isOpen, setIsOpen] = useState(false);

    if (isLoading || loadingFee) {
        return <Loading loading={isLoading} />;
    }

    return (
        <Container containerName={'landing'}>
            <Header
                title={'Notice!'}
                subtitle={'Please read the following:'}
                padding={true}
            />
            <NoticeGuestConfirm
                guest={guest}
                fee={fee}
                feeType={feeType}
                setGuest={setGuest}
                setLoading={setLoading}
                setIsOpen={setIsOpen}
            />
            <NoticeGuestModal
                fee={fee}
                setFee={setFee}
                day={day}
                today={today}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
        </Container>
    );
};

export default NoticeGuestPage;
