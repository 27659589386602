import GuestI from '../interfaces/Guest';
import { omit } from 'lodash';
import getModifiedStatus from './getModifiedStatus';

const goBack = (
    guest: GuestI,
    guestData: GuestI,
    setIsNotModified: (value: boolean) => void,
    history: any
) => {
    if (!guest) {
        return;
    }

    const unnecessaryProperties = Object.keys(guest).filter(
        (key: string) => !(key in guestData)
    );

    const newGuest = omit(guest, unnecessaryProperties) as GuestI;

    const isModified = !getModifiedStatus(newGuest, guestData);

    if (isModified) {
        setIsNotModified(false);
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return history.push(guest?.page as string);
};

export default goBack;
