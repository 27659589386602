import parseDate from '../functions/parseDate';

const EntryDates = ({ entryDates }: { entryDates: Date[] }) => {
    if (entryDates.length < 1) {
        return <></>;
    }

    return (
        <>
            {entryDates.map((entryDate, id) => (
                <option key={id} value={id}>
                    {parseDate(entryDate)}
                </option>
            ))}
        </>
    );
};

export default EntryDates;
