import { useState } from 'react';
import Loading from '../../components/Loading';
import UserForm from '../../forms/UserForm';
import User from '../../interfaces/User';
import Action from '../../interfaces/Action';
import Container from '../../components/Container';
import Header from '../../components/Header';
import Users from '../../components/Users';
import useUsers from '../../hooks/useUsers';

const UserPage = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [action, setAction] = useState<Action>({
        status: false,
        type: 'create',
    });
    const { users } = useUsers(setLoading, action);
    const [user, setUser] = useState<User | null>(null);

    if (loading) {
        return <Loading loading={loading} />;
    }

    if (action.status) {
        return (
            <Container containerName={'landing'}>
                <Header title={`${action.type} user`} subtitle={''} />
                <UserForm
                    action={action.type}
                    setAction={setAction}
                    setLoading={setLoading}
                    user={user}
                />
            </Container>
        );
    }

    return (
        <Container containerName={'landing'}>
            <Header title={'Users'} subtitle={''} bottomBorder={true} padding={true}/>
            <Users
                user={user}
                users={users}
                setUser={setUser}
                setAction={setAction}
                setLoading={setLoading}
            />
        </Container>
    );
};

export default UserPage;
