import { useEffect, useState } from 'react';
import GuestI from '../interfaces/Guest';
import axios from 'axios';
import Action from '../interfaces/Action';
import AllAccessGuestI from '../interfaces/AllAccessGuest';

const useGuests = (
    filter: string,
    action: Action,
    setLoading: (logic: boolean) => void,
    setPageNumber: (number: number) => void,
    setFilterType: (filter: string) => void,
    setInput: (input: string) => void
) => {
    const [guests, setGuests] = useState<GuestI[] | AllAccessGuestI[]>([]);

    useEffect(() => {
        (async () => {
            await setLoading(true);
            try {
                const { data } = await axios.get(
                    `/api/guests/all?type=${filter}`
                );

                return setGuests(data);
            } catch (error) {
                console.log(error);
            } finally {
                await setLoading(false);
            }
        })();

        setPageNumber(1);
        setFilterType('all');
        setInput('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action, filter]);

    return { guests, setGuests };
};

export default useGuests;
