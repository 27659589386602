import TransactionI from '../interfaces/Transaction';
import axios from 'axios';

const updateTransaction = async (
    id: string,
    transaction: TransactionI,
    setLoading: (value: boolean) => void,
    setAction: any
) => {
    setLoading(true);
    try {
        await axios.patch(`/api/transactions?id=${id}`, {
            ...transaction,
        });
    } catch (error) {
        console.log(error);
    } finally {
        setAction({ status: false, type: 'edit' });
        setLoading(false);
    }
};

export default updateTransaction;
