import { useState } from 'react';
import FeeForm from '../../forms/FeeForm';
import DiscountForm from '../../forms/DiscountForm';
import Action from '../../interfaces/Action';
import Container from '../../components/Container';
import Header from '../../components/Header';
import FeeMain from '../../components/FeeMain';

const FeePage = () => {
    const [feeType, setFeeType] = useState<string>('default');
    const [action, setAction] = useState<Action>({ status: false, type: '' });

    if (action.status) {
        if (action.type === 'default') {
            return <></>;
        }

        if (action.type === 'OtherFee') {
            return (
                <Container containerName={'landing'}>
                    <Header
                        title={`Editing: ${feeType}`}
                        subtitle={''}
                        bottomBorder={true}
                    />
                    <DiscountForm feeType={feeType} setAction={setAction} />;
                </Container>
            );
        }

        return (
            <Container containerName={'landing'}>
                <Header
                    title={`Editing: ${feeType}`}
                    subtitle={''}
                    bottomBorder={true}
                />
                <FeeForm feeType={feeType} setAction={setAction} />;
            </Container>
        );
    }

    return (
        <Container containerName={'landing'}>
            <Header
                title={'Fees'}
                subtitle={''}
                bottomBorder={true}
                padding={true}
            />
            <FeeMain
                feeType={feeType}
                setFeeType={setFeeType}
                setAction={setAction}
            />
        </Container>
    );
};

export default FeePage;
