import GuestI from '../interfaces/Guest';
import User from '../interfaces/User';
import AllAccessGuestI from '../interfaces/AllAccessGuest';

const getFullName = ({
    firstName,
    lastName,
}: GuestI | AllAccessGuestI | User) => `${firstName.trim()} ${lastName.trim()}`;

export default getFullName;
