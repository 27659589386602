import { useContext, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import useSignature from '../../hooks/useSignature';
import GuestI from '../../interfaces/Guest';
import AllAccessGuestI from '../../interfaces/AllAccessGuest';
import GlobalContext from '../../contexts/GlobalContext';
import Container from '../../components/Container';
import Header from '../../components/Header';
import UpdateGuestForm from '../../forms/UpdateGuestForm';
import Loading from '../../components/Loading';
import useAge from '../../hooks/useAge';
import AgeWarningMessage from '../../components/AgeWarningMessage';
import Message from '../../components/Message';
import getFullName from '../../functions/getFullName';
import Button from '../../components/Button';
import FlexContainer from '../../components/FlexContainer';

const UpdateGuestPage = () => {
    const [isLoading, setLoading] = useState(false);
    const { guest, setGuest, user } = useContext<GuestI | AllAccessGuestI>(
        GlobalContext
    );
    const { signature, loadingSignature } = useSignature(guest);
    const { age } = useAge(guest);
    const history = useHistory();

    if (isLoading || loadingSignature) {
        return <Loading loading={isLoading} />;
    }

    if (guest.standing === 'BAD') {
        return (
            <FlexContainer>
                <Header title={'Something went wrong!'} subtitle={''} />
                <Message
                    message={`${
                        guest?.stageName || getFullName(guest)
                    } is not currently on the guest list.`}
                    color={'green'}
                />
                <Button
                    color={'red'}
                    margin={true}
                    onClick={() =>
                        history.push(`/guests/checkin/${guest.type}`)
                    }
                >
                    Go back
                </Button>
            </FlexContainer>
        );
        // return <Redirect to="/dashboard" />;
    }

    if (!guest?.tos || !guest?.lease) {
        return <Redirect to="/guests/notice/tos" />;
    }

    if (!signature) {
        return <Redirect to="/guests/signature" />;
    }

    return (
        <Container containerName={'landing'}>
            <Header
                title={'Guest Found!'}
                subtitle={`You are checking in ${guest.firstName} ${guest.lastName} with guestID of ${guest.guestID}`}
                padding={true}
            />
            <AgeWarningMessage age={age} />
            <UpdateGuestForm
                guest={guest}
                setGuest={setGuest}
                user={user}
                setLoading={setLoading}
            />
        </Container>
    );
};

export default UpdateGuestPage;
