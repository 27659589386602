import FlexContainer from './FlexContainer';
import SelectFilter from './SelectFilter';
import Filters from './Filters';
import SelectGuestType from './SelectGuestType';
import GuestList from './GuestList';
import DatabasePagination from './DatabasePagination';
import Button from './Button';
import useFilteredGuests from '../hooks/useFilteredGuests';
import { MouseEvent, useContext, useState } from 'react';
import AgeInput from '../interfaces/AgeInput';
import DateInput from '../interfaces/DateInput';
import getGuest from '../functions/getGuest';
import GuestI from '../interfaces/Guest';
import { Redirect, useHistory } from 'react-router-dom';
import Action from '../interfaces/Action';
import useGuests from '../hooks/useGuests';
import GlobalContextI from '../interfaces/GlobalContext';
import GlobalContext from '../contexts/GlobalContext';
import GuestDatabaseModal from './modals/GuestDatabaseModal';
import Loading from './Loading';

const GuestDatabase = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>('limited');
    const [filterType, setFilterType] = useState<string>('all');
    const [input, setInput] = useState<string>('');
    const [action, setAction] = useState<Action>({
        status: false,
        type: 'unknown',
    });
    const [pageNumber, setPageNumber] = useState<number>(1);
    const { guests } = useGuests(
        filter,
        action,
        setLoading,
        setPageNumber,
        setFilterType,
        setInput
    );
    const { guest, setGuest } = useContext<GlobalContextI>(GlobalContext);
    const [ageInput, setAgeInput] = useState<AgeInput>({
        age1: null,
        age2: null,
    });
    const [dateInput, setDateInput] = useState<DateInput>({
        date1: '',
        date2: '',
    });
    const { filteredGuests } = useFilteredGuests(
        filterType,
        guests,
        input,
        ageInput,
        dateInput
    );
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const history = useHistory();

    const handleEdit = (e: MouseEvent<HTMLTableDataCellElement>) => {
        const guest = getGuest(e.currentTarget.id, guests) as GuestI;
        setGuest({ ...guest, page: history.location.pathname });
        setAction({ status: true, type: 'edit' });
    };

    const openModal = (e: MouseEvent<HTMLButtonElement>) => {
        const guest = getGuest(
            e.currentTarget.children[0].className,
            guests
        ) as GuestI;

        setGuest(guest);
        setIsOpen(true);
    };

    if (loading) {
        return <Loading loading={loading} />;
    }

    if (action.status) {
        return <Redirect to={`/editGuest/${guest?.type}`} />;
    }

    return (
        <FlexContainer>
            <SelectFilter
                filter={filter}
                filterType={filterType}
                setFilterType={setFilterType}
            />

            <Filters
                filter={filterType}
                dateInput={dateInput}
                ageInput={ageInput}
                setInput={setInput}
                setAgeInput={setAgeInput}
                setDateInput={setDateInput}
            />
            <SelectGuestType filter={filter} setFilter={setFilter} />
            <div className="user-container__text">
                <h2>{filter} Guests:</h2>
            </div>
            {guests !== null && (
                <GuestList
                    guestType={filter === 'allAccess' ? filter : 'limited'}
                    filterType={filterType}
                    pageNumber={pageNumber}
                    filteredGuests={filteredGuests}
                    handleEdit={handleEdit}
                    openModal={openModal}
                />
            )}
            <DatabasePagination
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                filteredGuests={filteredGuests}
            />
            <Button
                color={'red'}
                margin={true}
                onClick={() => history.push('/dashboard')}
            >
                Back
            </Button>
            <GuestDatabaseModal
                guest={guest}
                guests={guests}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                setLoading={setLoading}
                setAction={setAction}
            />
        </FlexContainer>
    );
};

export default GuestDatabase;
