const Message = ({ message, color }: { message: string; color: string }) => {
    return (
        <div>
            <p className="landing-container__text">
                <span className={`${color}-span`}>{message}</span>
            </p>
        </div>
    );
};

export default Message;
