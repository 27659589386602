import { ReactNode } from 'react';

const Container = ({
    containerName,
    children,
}: {
    containerName: string;
    children: ReactNode;
}) => <div className={`${containerName}-container`}>{children}</div>;

export default Container;
