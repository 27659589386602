import FlexContainer from './FlexContainer';
import SelectFee from './SelectFee';
import Button from './Button';
import { useHistory } from 'react-router-dom';
import Action from '../interfaces/Action';

const FeeMain = ({
    feeType,
    setFeeType,
    setAction,
}: {
    feeType: string;
    setFeeType: (value: string) => void;
    setAction: (action: Action) => void;
}) => {
    const history = useHistory();
    return (
        <FlexContainer>
            <h3 className="fee-container__text">
                Select what you would like to edit:
            </h3>

            <SelectFee feeType={feeType} setFeeType={setFeeType} />

            <Button
                color={'green'}
                margin={true}
                onClick={() =>
                    setAction({
                        status: feeType !== 'default',
                        type: feeType,
                    })
                }
            >
                Continue
            </Button>
            <Button
                color={'red'}
                margin={true}
                onClick={() => history.push('/dashboard')}
            >
                Go back
            </Button>
        </FlexContainer>
    );
};

export default FeeMain;
