import Modal from 'react-modal';
import Button from '../Button';
import AllAccessGuestI from '../../interfaces/AllAccessGuest';
import activateAccount from '../../functions/activateAccount';

const UpdateGuestModal = ({
    guest,
    setGuest,
    setRenewalStatus,
    isOpen,
    setIsOpen,
    feeType,
    setGuestPoints,
}: {
    guest: AllAccessGuestI;
    setGuest: (guest: AllAccessGuestI) => void;
    setRenewalStatus: (value: boolean) => void;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    feeType: string;
    setGuestPoints: (value: number) => void;
}) => {
    const handleActivateAccount = async () => {
        try {
            const dataGuest = await activateAccount(guest, feeType);
            await setGuest(dataGuest);

            setGuestPoints(1);
            setRenewalStatus(true);

            return setIsOpen(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleRejectActivation = async () => {
        await setRenewalStatus(true);

        return setIsOpen(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            ariaHideApp={false}
            className="modal"
        >
            <header className="modal__header">
                <h1 className="modal__header--h1">
                    <span className="green-span">{guest.guestID}'s</span>{' '}
                    account is not active. It will require{' '}
                    <span className="green-span">100$</span> from guest to renew
                    it.
                </h1>
            </header>

            <Button
                color={'green'}
                margin={true}
                onClick={handleActivateAccount}
            >
                Yes
            </Button>

            <Button
                color={'red'}
                margin={true}
                onClick={handleRejectActivation}
            >
                No
            </Button>
        </Modal>
    );
};

export default UpdateGuestModal;
