const Tos = () => {
    return (
        <div className="tos-page">
            <header>
                <h1>Terms Of Service</h1>
            </header>
            <p>
                {' '}
                ALL persons entering these premises MUST be a licensee or Guest.
            </p>
            <p> I agree that I must be a licensee BEFORE becoming a Member. </p>
            <p> I hereby agree to purchase a license </p>
            <p>
                {' '}
                For a sum of $5, of which I have paid today, I am entitled to
                use the premises of The Outhouse betwseen the hours of 7pm and
                2am, Sun-Wed and 7pm to 4am Thursday Friday and Saturday. I
                agree that a processing charge or facility charge may be
                required for the use of these premises based on the hours or day
                I wish to occupy the premises. I understand this charge may vary
                based on occupancy and/or upcoming events or reservations.{' '}
            </p>
            <p>
                I agree that as a condition of this License agreement, I MUST
                follow all house rules posted and not posted and behave in a
                respectul manner.{' '}
            </p>
            <p>
                I agee that this agreement may be terminated immediately and
                without notice if I do not follow all rules and regulations or
                behave in a manner that interferes with other Licensees
                enjoyment of the premises.
            </p>
            <p>
                I agree that If I am requested to vacate the premises and do not
                comply, I am trespassing and will be subject to prosecution.
            </p>
            <p>
                I agree that I will comply with all local, state, and federal
                laws.
            </p>
            <p>
                I agree that The Outhouse does not provide or offer any
                entertainment services.
            </p>
            <p>
                I agree that the other Licensees or guests that choose to dance
                or entertain, are NOT employees, contractors or representatvies
                of the outhouse in any way or fashion.
            </p>
            <p>
                I agree that the only legal right this agreement has given me,
                is the right to enter the premises during the specified hours
                above and ONLY while I am behaving in a manner consistent with
                The Outhouse policies.{' '}
            </p>
            <p>
                I agree that I am aware that nudity may exist on these premises.
            </p>
            <p>
                I agee that I am aware that second hand smoke is a health
                hazzard and may exist on these premises.
            </p>
            <p>
                I agree that if any un safe situation exists, I will report it
                to management immediately.
            </p>
            <p>
                I agree that I am soley resposible for my own possessions and my
                own personal well being.
            </p>
            <p>
                I agree that the agreement listed below for membership also
                applies to Licensees and I accept the terms and conditions as
                part of this agreement.
            </p>
            <p>
                I agree that I am entering at my own risk and take full
                responsibility for my own actions.
            </p>
            <p>I agree that no refunds are given for any reason</p>
            <p>
                I agree that if I get a sacrifice on stage, I am personally
                responsible for any and everything that happens and understand
                that pain may be involved and hereby hold no one responsible but
                myself.
            </p>
            <p>
                I agree that if the above terms are not acceptable, I will NOT
                enter the premises.{' '}
            </p>
            <p>
                I agree that if I enter the premises without entering this
                agreement, I am criminially trespassing and subject to immediate
                prosecution.
            </p>
            <p>
                This License agreemeent shall begin now and continue at the
                close of today's business.
            </p>
            <p>
                This agreement is between Me and The owner of the premises. A
                written copy of this agreement shall be made available at my
                request via US mail.
            </p>
            <p>
                Pursuant to the Electronic Records Act, this agreement shall be
                considered in force upon my clicking the accept button on this
                form.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>I
            <p>
                <b> AGREEMENT </b>
            </p>
            <br></br>
            <p>
                {' '}
                I am entering these premises as a member, newly signed Licensee,
                or invited guest ONLY. I understand the premises are NOT open to
                the general public and I am not entering as such.
            </p>
            <p>
                {' '}
                I acknowledge and agree that I have paid a $5.00 nonrefundable
                processing fee to have my request for membership submitted
                electronically to the membership committee. This processing fee
                is exactly what it states, A PROCESSING FEE and NOT for any
                other purpose expressed or implied. This processing fee is for
                the submission of my request to become a member. This processing
                fee is separate from any other fees, charges, deposits,
                membership dues, etc. Any Payment made over the $10.00
                Processing Fee is to be applied as a fee to enter the premises.
                This facility usage fee includes Kansas retailer's sales Tax a
                receipt for which may be requested reflecting the charges and
                applicable taxes.
            </p>
            <p>
                {' '}
                The Association does not require or accept Deposits for
                membership or Initiation Fees. The Fees I am paying are for the
                initial Processing Fee and Facility Usage Fees. A printed or
                written receipt for the processing fee and Facility Usage Fees
                is available upon request but ONLY at the time of Entry or
                processing of my request to become a member. I ACKNOWLEDGE AND
                AGREE, that if I submit a payment for or toward my membership
                dues with my application (1) the acceptance by the club of my
                payment or partial payment of initiation fee or deposit
                ("deposit"), shall and does not constitute acceptance of the
                undersigned as a club member; (2) if my deposit is returned to
                me for any reason, the club has no obligation to pay interest on
                my deposit; (3) if I am accepted for membership no portion of
                the deposit is refundable except as otherwise provided in the
                club's bylaws, and I request its return by written notification
                to and received by the club and the withdrawal of my membership
                nomination prior to that date; (4) if I am not accepted for
                membership the deposit is refundable at the time I am so
                notified; and (5) the club is a distinctly private club with the
                right to reject any and all nominees for membership. I further
                agree if I am placed in a "waiting pool" or "provisional list"
                that I do not have a vested interest in any particular priority
                with respect to such waiting pool or such provisional list and
                the club may, in its discretion, either grant other persons
                priority over me or vice-versa as the club, in its sole
                discretion, deems suitable; (6) IF, I submit a deposit, it is my
                responsibility to get a receipt reflecting the amount paid being
                applied toward a Deposit.
            </p>
            <p>
                {' '}
                I agree, if elected to membership, to pay all dues, fees,
                assessments and accounts as and at the time prescribed by the
                club, its membership bylaws, club rules, policies and procedures
                or otherwise, and to accept, abide, and be governed by the
                membership bylaws, club rules, policies and procedures of the
                club in force from time to time.{' '}
            </p>
            <p>
                {' '}
                I represent and warrant the information provided herein is true,
                correct and complete, and, if discovered to be untrue or
                incomplete, I acknowledge shall be grounds for expulsion from
                the club. The club is authorized to make all inquiries among
                other sources of information it considers necessary to verify
                the accuracy of this information and to determine my suitability
                for membership. I hereby authorize the club to collect and
                store, by whatever means the club deems necessary, my personal
                information, including, but not limited to the information I
                presented or submitted with my request to become a member of the
                club along with any or all data or information collected from
                the Identification I presented at the time I entered the club or
                submitted a request to become a member whether known or unkown
                to me.
            </p>
            <p>
                {' '}
                Any person or entity listed herein is hereby released from and
                against any claims which might otherwise be brought by me for
                disclosing such information as is solicited by the club from
                them concerning me, but not limited to defamation.{' '}
            </p>
            <p>
                {' '}
                I guarantee payment to the Club, All fees, assessments, accounts
                and other indebtedness or damage to club property which may be
                incurred as the result of my conduct or the conduct of my
                guests.{' '}
            </p>
            <p>
                {' '}
                I acknowledge that I was not notified of the opportunity to
                acquire membership through the use of a newspaper, Internet or
                other media advertisement or public solicitation.{' '}
            </p>
            <p>
                I authorize all persons and entities to disclose to the club
                such information as may be pertinent to this nomination,
                including but not limited to, my credit worthiness, social
                congeniality and compatibility, reputation for trustworthiness,
                and general reputation in the community for good character and
                social behavior.{' '}
            </p>
            <b>Facility Usage Fee </b>
            <br></br>
            <p>
                {' '}
                I agree that a Facility Usage fee charged by the club is for the
                privilege of Using the premises. I agree this Facility Usage fee
                is non-refundable. I agree this Facility Usage Fee is separate
                from any other fees and no other rights are granted, expressed
                or implied other than the privilege of entering the premises of
                which may be revoked at any time at the discretion of the club.
                This Facility Usage Fee shall include all applicable sales tax
                due to the state of Kansas. I agree that an itemized sales
                receipt will be provided to me upon request only and only at the
                time of entry to the premises. I agree that I entering the
                premises of the Association as an individual representing only
                myself and NOT as an agent for any other person or entity and
                NOT on bahalf of or in representation of the Association. My
                actions are of my own accord and free will and not under the
                direction of or on behalf of any other persons or enitity
                associated with or not associated with the Association or club.
            </p>
            <b>HOUSE RULES </b>
            <br></br>
            <p>
                {' '}
                I agree that I am aware of all house rules posted and NOT
                posted. I agree it is my responsibility to know these rules and
                or regulations and to abide by them. If I do not abide by the
                rules or regulations, I understand I will be asked to leave the
                premises. I am aware that if I do not leave the premises when
                requested by club personnel, I am trespassing and may be subject
                to criminal prosecution.{' '}
            </p>
            <p>
                House Rule violations harm the functionality, integrity, and
                reputation of the association; interfere with and detract from
                other member's beneficial use and enjoyment of the association;
                and are detrimental to the Association as an entity. I hereby
                acknowledge and agree that The Association incurs actual damages
                as a result of House Rule violations... I further acknowledge
                and agree that actual damages caused by such violations are
                extremely difficult, impossible or impractical to determine or
                quantify. Consequently, I agree it is fair and reasonable for
                The Association to obtain injunctive relief to prohibit future
                violations of the House Rules, Association's Policies, bylaws or
                membership agreements and recover liquidated damages for past
                violations of the House Rules, Association's Policies, bylaws or
                membership agreements, and I acknowledge that the amounts set
                forth below reflect reasonable estimates of The Association's
                actual damages from each such violation and that such estimates
                are reasonably related to the actual damages caused to The
                Association by each such violation.
            </p>
            <p>
                for each House Rule violation below, I therefore agree to pay
                liquidated damages to The Association in the amount specified. I
                further acknowledge and agree that the imposition of liquidated
                damages for each such House Rule violation is independent and
                distinct, and that the assessment of total liquidated damages
                for multiple House Rule violations is cumulative.
            </p>
            <p>
                :1. $100 liquidated damages for violating any Rule listed on
                House Rule posted by Entry Door
            </p>
            <p>
                2. $2500 liquidated damages for Providing Alcohol to any person
                under the Age of 21
            </p>
            <p>
                3. $10,000 liquidated damages for bringing Drugs or other
                illegal items on to Association Property
            </p>
            <p>
                4. $10,000 liquidated damages for engaging in any illegal
                activity.
            </p>
            <p>
                5. $5,000 liquidated damages for posting any comments in ANY
                forum that portray the Association in a negative way.
            </p>
            <p>
                6. $5,000 liquidated damages for false statements made to any
                person or entity pertaining to the Association
            </p>
            <p>
                7. $250 liquidated damages for any involvement with any escort
                activities including private bachelor parties
            </p>
            <p>
                8. $200-$10,000 liquidated damages for violating Confidentiality
                agreement
            </p>
            <b>GENERAL RELEASE and WAIVER </b>
            <br></br>
            <p>
                {' '}
                In consideration of my Entry to, participation in, or the use of
                the premises, I hereby release and covenant not to sue the Club,
                its owners, shareholders, directors, officers, employees,
                representatives, agents, members orlessees from any and all
                present and future claims resulting from ordinary negligence and
                inherent risk of liability sustained by me while on or about
                premises of the Club.
            </p>
            <p>
                {' '}
                I am fully aware and understand that the Club does not have on
                or about the Club premises, or employ or contract with any
                medical services, provisions for ordinary or emergency medical
                services including, but not limited to, emergency cardiovascular
                assistance.
            </p>
            <p>
                {' '}
                I am also fully aware and understand that such losses, injury,
                disability or death may result from the actions, inactions or
                negligence on my part, or the part of the Club, on the part of
                others, the riles of play or the condition of the Club's
                premises, fixtures, and equipment.
            </p>
            <p>
                {' '}
                I agree that immediately prior to participation in any activity
                accruing in or about the Club's premises, I will inspect the
                facilities, fixtures, and equipment to be used and if any defect
                is apparent I will not use the facilities, fixtures or equipment
                and I will notify the management of the Club of the defect
                verbally and in writing.
            </p>
            <p>
                {' '}
                I agree that if I engage in any activity including but not
                limited to dancing with another member of the club, I am doing
                so at my own discretion, at my own free will and at my own risk.
            </p>
            <p>
                {' '}
                I agree that if I am "sacrificed" by another member or members
                whether requested by myself or not, that I voluntarily submitted
                myself for the "sacrifice" and understood that being
                "sacrificed" could and mostly WOULD result in serious pain and
                suffering to my physical self as well as my mental well-being. I
                am aware that "sacrifices" consists of other members of the club
                physically assaulting me with whips or belts or other objects
                requested by me or on my behalf by other members. I agree that
                sitting in the "sacrifice" chair is evidence of my CONSENT to be
                "sacrificed".
            </p>
            <p>
                {' '}
                I agree that if I am not knowledgeable in the proper use of any
                of the Club's facilities or equipment, I will obtain proper
                instruction for the correct use of such facility or equipment
                from a qualified individual before I will use the facility or
                equipment.
            </p>
            <p>
                {' '}
                I further agree to indemnify and hold harmless the Club, its
                owners, shareholders, directors, officers, employees,
                representatives, agents, members and lessees for any and all
                claims arising from involvement in or receiving instruction from
                the Club activities incidental thereto where to wherever,
                whenever and however the claims may arise including but not
                limited to travel from the Club or related activity site and
                participation at remote sites.{' '}
            </p>
            <p> I am aware of the dangers of Second hand smoke. </p>
            <p>
                {' '}
                I am fully aware that smoking is permitted on the premises and
                is hazardous to my health
            </p>
            <p>
                {' '}
                If I choose to consume alcohol, I will only consume alcohol that
                I have brought with me for my own personal consumption and I
                will do so in a safe and responsible manner. I am solely
                responsible for making the decision to operate or not to operate
                a motor vehicle to or from the club premises.
            </p>
            <p>
                {' '}
                I am aware that members of the association may appear in a state
                of nudity and I am not offended in any way by such state of
                undress.{' '}
            </p>
            <p>
                {' '}
                I assume all the foregoing risks and accept personal
                responsibility for any damages and loss following any of
                property, injury, permanent disability or death resulting there
                from.
            </p>
            <p>
                {' '}
                I am Seeking membership to the Association for the sole purpose
                of "associating" with other persons who have the same interest
                as myself. My interest in Joining this Association is purely for
                the enjoyment of exercising my First Amendment rights afforded
                me by the U.S. Constitution. My ONLY intention for wanting to
                join the Association is RECREATIONAL. I have NO intentions of
                joining the Association for trade, business, or other purposes
                including monetary gain. I am fully aware that the Association
                EXPLICITLY prohibits the sale of any goods or services to or by
                any member or guest of a member including but not limited to any
                fees, tips, charges or anything collected or traded of value in
                exchange for any interactivity whatsoever. I agree that I am
                entering the premises and joining the Association for pleasure
                and recreational purposes ONLY. I agree that if another member
                or guest of a member gifts to me anything of value including but
                not limited to a tip, I will report it to the appropriate
                authorities. I agree that any gift or gifts may be a taxable
                event and it is my responsibility to report any such gift to the
                appropriate authorities for tax purposes. I agree that I am
                entering the premises with prospective member status or member
                of the Association status ONLY and not for any other STATUS or
                contractual agreement written, expressed or implied. I agree the
                ONLY relationship between myself and the Association that will
                EVER EXIST is a member/Association relationship. I agree that my
                relationship with the Association is limited to being a MEMBER
                of the Association and NOTHING more. I am aware that employees
                of the Association and Persons Contracted with the Association
                are NOT allowed to be members of the Association. I agree that
                if I ever want to apply to become an employee of the Association
                or become a contractor with the Association, I must resign my
                membership in the Association 30 days prior to my application
                for employment or 30 days prior to seeking a contractual
                relationship with the Association. I agree that my resignation
                must be submitted in writing and sent via Certified US Postal
                mail service with return receipt requested to the address of the
                Association Registered Agent. I understand that before I can or
                will be considered for an employee or contractural relationship,
                the Association is required to notify me of it's desire to form
                this relationship via Certified US Postal mail service with
                return receipt requested. Absence of this return receipt shall
                be evidence that no intent or desire to form any
                employee/employer or contractee/contractor or any other
                relationship other than a membership relationship ever existed
                or will ever exist.{' '}
            </p>
            <b>FORCE MAJEURE </b>
            <p>
                I agree that the Association is not responsible to me for
                anything that the Association may otherwise be responsible for,
                if it is the result of events beyond the Association's control,
                including, but not limited to, acts of God, war, insurrection,
                riots, terrorism, crime, labor shortages (including lawful and
                unlawful strikes), embargoes, postal disruption, communication
                disruption, failure or shortage of infrastructure, shortage of
                materials, or any other event beyond our control.{' '}
            </p>
            <b> SEVERABILITY</b>
            <p>
                In the event that a provision of this Agreement is found to be
                unlawful, conflicting with another provision of the Agreement,
                or otherwise unenforceable, the Agreement will remain in force
                as though it had been entered into without that unenforceable
                provision being included in it. If two or more provisions of
                this Agreement are deemed to conflict with each other's
                operation, the Association shall have the sole right to elect
                which provision remains in force.
            </p>
            <b> NON-WAIVER</b>
            <p>
                The Association reserves all rights permitted to it under this
                Agreement as well as under the provisions of any applicable law.
                The Association's non-enforcement of any particular provision or
                provisions of this Agreement or the any applicable law should
                not be construed as it's waiver of the right to enforce that
                same provision under the same or different circumstances at any
                time in the future.
            </p>
            <p>
                <b>CONFIDENTIALITY POLICY</b>
            </p>
            <p>Definitions</p>
            <p>1. The following terms have these meanings in this Policy :</p>
            <p>
                a) "Club" – AFAP, LLC, The Outhouse, The Outhouse members only
                or The Association
            </p>
            <p>
                b) "Club Member"- All categories of membership defined in the
                Club Bylaws.{' '}
            </p>
            <p>
                c) "Club Associate"- all individuals engaged in activities with
                the Club, including but not limited to, directors, officers,
                committee members, officials, volunteers, employees and
                administrators.
            </p>
            <p>
                {' '}
                d) "Confidential Information" shall mean ANY information gained
                or acquired from attending the premises of the club including
                but not limited to the following:
            </p>
            <p>
                <b>PURPOSE</b>
            </p>
            <p>
                2. The purpose of this policy is to ensure the protection of
                Confidential Information that is proprietary to the Club by
                making all Club Members and Club Associates aware that there is
                an expectation to act at all times appropriately and
                consistently with this policy.
            </p>
            <p>
                <b>APPLICATION OF THIS POLICY</b>
            </p>
            <p>This policy applies to all Club Members and Club Associates</p>
            <p>
                <b>RESPONSIBILITIES</b>
            </p>
            <p>
                1. Club Members or Club Associates will not, either during the
                period of their membership, or association with or any Time
                thereafter, disclose to any person or organization any
                Confidential Information acquired during their period of
                membership or any time on the club's premises, unless expressly
                authorized to do so by the Club.
            </p>
            <p>
                2. Club Members or Club Associates will not publish,
                communicate, divulge or disclose to any unauthorized person,
                firm, corporation, third party or parties any Confidential
                Information, without the express written consent of the Club.
            </p>
            <p>
                3. Club Members or Club Associates will not use, reproduce or
                distribute such Confidential Information or any part thereof,
                without the express written consent of the Club
            </p>
            <p>
                4. All files and written materials relating to Confidential
                Information will remain the property of the Club and upon
                termination of the membership or termination of the association
                with the Club or upon request of the Club, the Club Member or
                Club Associate will return all Confidential Information received
                in written or tangible form, including copies, or reproductions
                or other media containing such Confidential Information,
                immediately upon such request.
            </p>
            <p>
                5. The term "Confidential Information" includes, but it not
                limited to the following:
            </p>
            <p>
                a) Personal information of Club Members or club Associates,
                including but not limited to their respective, name, address,
                e-mail, telephone number, cell phone number, date of birth,
                Identification data and financial information;
            </p>
            <p>
                b) Club intellectual property and proprietary information
                related to the programs, fundraisers, business or affairs of the
                Club and any of its divisions, including, but not limited to,
                procedures, business methods, forms, policies, business,
                marketing and development plans, advertising programs, creative
                materials, trade secrets, knowledge, techniques, data, products,
                technology, Computer programs, manuals, software, financial
                information and information that is not generally or publicly
                known.
            </p>
            <p>
                <b>INTELLECTUAL PROPERTY</b>
            </p>
            <p>
                Copyright and any other intellectual property rights in all
                written material (including material in electronic format) and
                other works produced in connection with membership with the Club
                will be owned solely by the Club, who will have the right to
                use, reproduce or distribute such material and works, or any
                part thereof, for any purpose it wishes. The Club may grant
                permission for others to use such written material or other
                works, subject to such terms and conditions as the Club may
                prescribe.
            </p>
            <p>
                <b>Enforcement</b>
            </p>
            <p>
                A breach of any provision in this Agreement may give rise to
                discipline in accordance with the Club Discipline and Complaints
                Policy or legal recourse.
            </p>
        </div>
    );
};

export default Tos;
