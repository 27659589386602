import Agreement from './Agreement';
import { MouseEvent, useContext, useState } from 'react';
import useTransaction from '../hooks/useTransaction';
import GlobalContextI from '../interfaces/GlobalContext';
import GlobalContext from '../contexts/GlobalContext';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Loading from './Loading';
import FlexContainer from './FlexContainer';
import Text from './Text';

const TosGuestConfirm = () => {
    const { guest, user } = useContext<GlobalContextI>(GlobalContext);
    const [loading, setLoading] = useState<boolean>(true);
    const { transaction } = useTransaction(guest, setLoading);
    const history = useHistory();

    if (loading) {
        return <Loading loading={loading} />;
    }

    const deleteTransaction = async (e: MouseEvent<HTMLButtonElement>) => {
        try {
            await axios.delete(`/api/transactions?id=${e.currentTarget.id}`);

            return history.push('/dashboard');
        } catch (error) {
            console.log('error');
        }
    };

    return (
        <FlexContainer>
            <Text>
                <span className="green-span">
                    {guest.type === 'allAccess'
                        ? guest.stageName
                        : guest.guestID}
                </span>{' '}
                has been successfully checked in to The Venue and{' '}
                <span className="green-span">${`${transaction?.value} `}</span>
                has been collected by{' '}
                <span className="green-span">{user?.firstName}</span>{' '}
                <span className="green-span">{user?.lastName}</span>
                <span className="green-span">({user?.login})</span>. If the
                guest was checked in mistakenly or guest changed their mind{' '}
                <span
                    id={transaction?._id}
                    onClick={deleteTransaction}
                    className="green-span-href"
                >
                    click here
                </span>
                .
            </Text>
            <Agreement guest={guest} setLoading={setLoading} />
        </FlexContainer>
    );
};

export default TosGuestConfirm;
