const getGuestID = (guest: {
    firstName: string;
    lastName: string;
    licenseID: string;
}) =>
    guest.firstName[0] +
    guest.lastName[0] +
    guest.licenseID.substr(guest.licenseID.length - 4);

export default getGuestID;
