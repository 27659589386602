const SelectGuestType = ({
    filter,
    setFilter,
}: {
    filter: string;
    setFilter: any;
}) => {
    return (
        <>
            <span className="form-span margin">Select type of guests</span>
            <select
                className="select-input"
                name="guest-type"
                defaultValue={filter}
                onChange={(e) => setFilter(e.target.value)}
            >
                <option value="limited">Limited</option>
                <option value="allAccess">All Access</option>
            </select>
        </>
    );
};

export default SelectGuestType;
