import DisabledGuestForm from '../../forms/DisabledGuestForm';
import Container from '../../components/Container';
import Header from '../../components/Header';
import { useContext, useEffect } from 'react';
import sendEmailToGuest from '../../functions/sendEmailToGuest';
import GlobalContext from '../../contexts/GlobalContext';

const GuestConfirmationPage = () => {
    const { user, guest } = useContext(GlobalContext);

    useEffect(() => {
        (async () => sendEmailToGuest(guest, user))();
    }, [guest, user]);

    return (
        <Container containerName={'landing'}>
            <Header
                title={'Confirmation Page'}
                subtitle={''}
                bottomBorder={true}
            />
            <DisabledGuestForm />
        </Container>
    );
};

export default GuestConfirmationPage;
