import { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ParamTypes from '../interfaces/ParamTypes';
import GlobalContextI from '../interfaces/GlobalContext';
import GlobalContext from '../contexts/GlobalContext';
import Loading from '../components/Loading';
import getAgreement from '../functions/getAgreement';
import EntryDates from '../components/EntryDates';
import FlexContainer from '../components/FlexContainer';

const DisabledGuestForm = () => {
    const { user, guest } = useContext<GlobalContextI>(GlobalContext);
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const { type }: ParamTypes = useParams();

    const handleAgreement = async (e: any) => {
        const id = e.target.value;
        if (id === 'default') {
            return;
        }

        await getAgreement(guest, user, setLoading, parseInt(id, 10));
    };

    if (loading) {
        return <Loading loading={loading} />;
    }

    return (
        <FlexContainer>
            <div>
                <span className="form-span">Entries: </span>
                <select className="form-input" onChange={handleAgreement}>
                    <option value="default">
                        Choose entry to download a file
                    </option>
                    {guest?.entryDates && (
                        <EntryDates entryDates={guest.entryDates} />
                    )}
                </select>
            </div>
            <div>
                <span className="form-span">First Name: </span>
                <input
                    type="text"
                    className="form-input"
                    placeholder="enter first name..."
                    defaultValue={guest?.firstName || ''}
                    disabled
                />
            </div>
            <div>
                <span className="form-span">Middle Name: </span>
                <input
                    type="text"
                    className="form-input"
                    placeholder="enter middle name..."
                    defaultValue={guest?.middleName || ''}
                    disabled
                />
            </div>
            <div>
                <span className="form-span">Last Name: </span>
                <input
                    type="text"
                    className="form-input"
                    placeholder="enter last name..."
                    defaultValue={guest?.lastName || ''}
                    disabled
                />
            </div>
            <div>
                <span className="form-span">Date of Birth: </span>
                <input
                    type="text"
                    className="form-input"
                    placeholder="enter date of birth..."
                    defaultValue={(guest?.dateOfBirth as string) || ''}
                    disabled
                />
            </div>
            <div>
                <span className="form-span">Street Address: </span>
                <input
                    type="text"
                    className="form-input"
                    placeholder="enter street address..."
                    defaultValue={guest?.streetAddress || ''}
                    disabled
                />
            </div>
            <div>
                <span className="form-span">City: </span>
                <input
                    type="text"
                    className="form-input"
                    placeholder="enter city..."
                    defaultValue={guest?.city || ''}
                    disabled
                />
            </div>
            <div>
                <span className="form-span">State: </span>
                <input
                    type="text"
                    className="form-input"
                    placeholder="enter state..."
                    defaultValue={guest?.state || ''}
                    disabled
                />
            </div>
            <div>
                <span className="form-span">License #: </span>
                <input
                    type="text"
                    className="form-input"
                    placeholder="enter driver license number..."
                    defaultValue={guest?.licenseID || ''}
                    disabled
                />
            </div>
            <div>
                <span className="form-span">Expiration Date: </span>
                <input
                    type="text"
                    className="form-input"
                    placeholder="enter driver license expiration date..."
                    defaultValue={guest?.licenseExpDate || ''}
                    disabled
                />
            </div>
            <div>
                <span className="form-span">Sex: </span>
                <select
                    className="form-input"
                    defaultValue={guest?.sex || 'M'}
                    disabled
                >
                    <option value="M">M</option>
                    <option value="F">F</option>
                </select>
            </div>
            <div>
                <span className="form-span">Eyes: </span>
                <input
                    type="text"
                    className="form-input"
                    placeholder="enter eye color..."
                    defaultValue={guest?.eyes || ''}
                    disabled
                />
            </div>
            <div>
                <span className="form-span">Height: </span>
                <input
                    type="text"
                    className="form-input"
                    placeholder="enter height..."
                    defaultValue={guest?.height || ''}
                    disabled
                />
            </div>
            <div>
                <span className="form-span">Weight: </span>
                <input
                    type="text"
                    className="form-input"
                    placeholder="enter weight..."
                    defaultValue={guest?.weight || ''}
                    disabled
                />
            </div>
            <div>
                <span className="form-span">Phone Number: </span>
                <input
                    type="text"
                    className="form-input"
                    placeholder="enter phone number..."
                    defaultValue={guest?.phoneNumber || ''}
                    disabled
                />
            </div>
            <div>
                <span className="form-span">Email Address: </span>
                <input
                    type="email"
                    className="form-input"
                    placeholder="enter email address..."
                    defaultValue={guest?.email || ''}
                    disabled
                />
            </div>
            {type === 'allAccess' && (
                <>
                    <div>
                        <span className="form-span">*Stage Name: </span>
                        <input
                            type="text"
                            className="form-input"
                            placeholder="enter stage name..."
                            defaultValue={guest?.stageName || ''}
                            disabled
                        />
                    </div>
                    <div>
                        <span className="form-span">*Loyalty Points: </span>
                        <input
                            type="text"
                            className="form-input"
                            placeholder="enter points..."
                            defaultValue={guest?.points || '0'}
                            disabled
                        />
                    </div>
                    <div>
                        <span className="form-span">*Discount: </span>
                        <input
                            type="text"
                            className="form-input"
                            placeholder="enter Discount..."
                            defaultValue={guest?.discount || '0'}
                            disabled
                        />
                    </div>
                </>
            )}
            <div>
                <button
                    className="green-button margin"
                    onClick={() =>
                        history.push(`/guests/checkin/${guest?.type}`)
                    }
                >
                    Scan another guest
                </button>
            </div>
            <div>
                <button
                    className="red-button margin"
                    onClick={() => history.push('/dashboard')}
                >
                    Go back to dashboard
                </button>
            </div>
        </FlexContainer>
    );
};

export default DisabledGuestForm;
