const SelectFilter = ({
    filter,
    filterType,
    setFilterType,
}: {
    filter: string;
    filterType: string;
    setFilterType: any;
}) => {
    return (
        <>
            <span className="form-span">Select Type of Filter:</span>
            <select
                className="select-input"
                name="filter-type"
                defaultValue={filterType}
                onChange={(e) => setFilterType(e.target.value)}
            >
                <option value="all">All</option>
                <option value="guestID">guestID</option>
                <option value="keyword">Keyword</option>
                <option value="age">Age Range</option>
                {filter === 'allAccess' ? (
                    <option value="stageName">Stage Name</option>
                ) : (
                    <option value="fullName">Full Name</option>
                )}
                <option value="date">Date Range</option>
            </select>
        </>
    );
};

export default SelectFilter;
