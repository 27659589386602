import { Route, Redirect } from 'react-router-dom';
import Loading from '../components/Loading';
import { useContext } from 'react';
import GlobalContext from '../contexts/GlobalContext';

const PublicRoute = ({ children, ...rest }: any) => {
    const { user, isLoading } = useContext(GlobalContext);

    if (isLoading) {
        return (
            <div>
                <Loading loading={isLoading} />
            </div>
        );
    }

    return (
        <Route {...rest}>
            {user ? <Redirect to="/dashboard" /> : children}
        </Route>
    );
};

export default PublicRoute;
