import { useState, useEffect } from 'react';
import axios from 'axios';
import User from '../interfaces/User';
const useUser = () => {
    const [user, setUser] = useState<User | null>(null);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axios.get('/api/users/me');

                setUser(data);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return {
        user,
        setUser,
        isLoading,
        setLoading,
    };
};

export default useUser;
