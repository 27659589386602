import axios from 'axios';

const updateFee = async (
    fee: ({ day: number; name: string; value: number } | undefined)[],
    feeType: string,
    setLoading: (value: boolean) => void,
    setAction: any
) => {
    setLoading(true);
    try {
        await axios.patch(`/api/fees?type=${feeType}&updateType=name`, {
            ...fee,
        });
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
        setAction({ status: false, type: feeType });
    }
};

export default updateFee;
