import Container from '../../components/Container';
import Header from '../../components/Header';
import GuestDatabase from '../../components/GuestDatabase';

const GuestDatabasePage = () => (
    <Container containerName={'landing'}>
        <Header title={'Guest Database'} subtitle={''} bottomBorder={true} />
        <GuestDatabase />
    </Container>
);

export default GuestDatabasePage;
