import TransactionsI from '../interfaces/Transactions';
import { useEffect, useState } from 'react';
import Statistics from '../interfaces/Statistics';
import getFees from '../functions/getFees';

const useStatistics = (transactions: TransactionsI) => {
    const [statistics, setStatistics] = useState<Statistics | null>(null);

    useEffect(() => {
        if (!transactions) {
            return;
        }
        const allAccess = transactions.allAccess;
        const limitedAccess = transactions.limited;

        const statistics = {
            allAccessGuests: allAccess.length,
            limitedAccessGuests: limitedAccess.length,
            allAccessFees: getFees('allAccess', transactions),
            limitedAccessFees: getFees('limitedAccess', transactions),
            totalFees: getFees('total', transactions),
        };

        setStatistics(statistics);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { statistics, setStatistics };
};

export default useStatistics;
