import Modal from 'react-modal';
import { MouseEvent } from 'react';
import deleteGuest from '../../functions/deleteGuest';
import GuestI from '../../interfaces/Guest';

const GuestDatabaseModal = ({
    guest,
    guests,
    isOpen,
    setIsOpen,
    setLoading,
    setAction,
}: {
    guest: GuestI;
    guests: GuestI[];
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    setLoading: (value: boolean) => void;
    setAction: any;
}) => {
    const handleDelete = async (e: MouseEvent<HTMLButtonElement>) =>
        await deleteGuest(
            e.currentTarget.id,
            guests,
            setLoading,
            setIsOpen,
            setAction
        );

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            ariaHideApp={false}
            className="modal"
        >
            <>
                <header className="modal__header">
                    <h1 className="modal__header--h1">
                        Do you want to delete guest:{' '}
                        <span className="green-span">{guest?.guestID}</span>
                    </h1>
                </header>
                <button
                    className="green-button margin"
                    id={guest?._id}
                    onClick={handleDelete}
                >
                    Yes
                </button>
                <button
                    className="red-button margin"
                    onClick={() => setIsOpen(false)}
                >
                    No
                </button>
            </>
        </Modal>
    );
};

export default GuestDatabaseModal;
