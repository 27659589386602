import parseHour from '../functions/parseHour';

const Options = () => {
    let hours: string[] = [];

    for (let i = 0; i <= 23; i++) {
        hours[i] = parseHour(i);
    }

    if (hours.length < 1) {
        return <></>;
    }

    return (
        <>
            {hours.map((hour) => (
                <option key={hour}>{hour}</option>
            ))}
        </>
    );
};

export default Options;
