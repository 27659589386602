import FlexContainer from './FlexContainer';
import AgeWarningMessage from './AgeWarningMessage';
import Button from './Button';
import { useState } from 'react';
import getFullName from '../functions/getFullName';
import useAge from '../hooks/useAge';
import fetchTransaction from '../functions/fetchTransaction';
import FeeI from '../interfaces/Fee';
import axios from 'axios';
import { omit } from 'lodash';
import { useHistory } from 'react-router-dom';
import GuestI from '../interfaces/Guest';

const NoticeGuestConfirm = ({
    guest,
    fee,
    feeType,
    setGuest,
    setLoading,
    setIsOpen,
}: {
    guest: GuestI;
    fee: FeeI | null;
    feeType: string;
    setGuest: (guest: GuestI) => void;
    setLoading: (value: boolean) => void;
    setIsOpen: (value: boolean) => void;
}) => {
    const { age } = useAge(guest);
    const [stageName] = useState<string | null>(
        guest?.stageName || `${getFullName(guest)} (${guest.guestID})`
    );
    const history = useHistory();

    const addGuest = async () => {
        setLoading(true);

        try {
            const emptyProperties = Object.keys(guest).filter(
                (key) => guest[key] === ''
            );

            const { data } = await axios.post(
                `/api/guests/${guest.type}/create`,
                {
                    ...omit(guest, emptyProperties),
                }
            );

            await setGuest(data);
            return history.push('/guests/notice/tos');
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleTransaction = async (e: any) => {
        e.preventDefault();

        await fetchTransaction(fee as FeeI, feeType, guest, addGuest);
    };

    return (
        <FlexContainer>
            <AgeWarningMessage age={age} />

            <p className="landing-container__text">
                <span className="green-span">{stageName}</span> is{' '}
                <span className="red-span">{age} years old.</span>{' '}
                <span className="green-span">{stageName}</span> is currently on
                the guest list. The fee for{' '}
                <span className="green-span">{stageName}</span> to use the club
                today is{' '}
                <span className="green-span">
                    ${fee ? fee.value : 'Loading...'}
                </span>
                . is this fee agreeable?
            </p>

            {age >= 18 && (
                <Button
                    color={'green'}
                    margin={true}
                    onClick={handleTransaction}
                >
                    Yes
                </Button>
            )}

            <Button
                color={'red'}
                margin={true}
                onClick={() => history.push('/dashboard')}
            >
                No
            </Button>
            <Button
                color={'yellow'}
                margin={true}
                onClick={() => setIsOpen(true)}
            >
                Override Fee
            </Button>
        </FlexContainer>
    );
};

export default NoticeGuestConfirm;
