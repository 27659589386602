import GuestListI from '../interfaces/GuestList';
import paginate from '../functions/paginate';
import GuestI from '../interfaces/Guest';
import AllAccessGuestI from '../interfaces/AllAccessGuest';
import parseDate from '../functions/parseDate';
import DeleteIcon from '../img/delete.svg';
import sortGuests from '../functions/sortGuests';

const GuestList = ({
    guestType,
    filterType,
    pageNumber,
    filteredGuests,
    handleEdit,
    openModal,
}: GuestListI) => {
    return (
        <div>
            <table className="user-container__form--table">
                <thead>
                    <tr>
                        <th>
                            {guestType === 'allAccess' ? guestType : 'limited'}
                        </th>
                        <th>Status</th>
                        <th>Standing</th>
                        <th>Recent Entry</th>
                    </tr>
                </thead>
                <tbody>
                    {paginate(
                        sortGuests(guestType, filterType, filteredGuests),
                        pageNumber
                    )
                        .filter(({ type }: GuestI) => type === guestType)
                        .map(
                            ({
                                _id,
                                stageName,
                                firstName,
                                lastName,
                                tos,
                                standing,
                                entryDates,
                            }: GuestI | AllAccessGuestI) => (
                                <tr id={_id} key={_id}>
                                    {guestType === 'allAccess' ? (
                                        <td
                                            className="transaction-container__form--table-stage"
                                            id={_id}
                                            onClick={handleEdit}
                                        >
                                            {stageName.toUpperCase()}
                                        </td>
                                    ) : (
                                        <td
                                            className="transaction-container__form--table-stage"
                                            id={_id}
                                            onClick={handleEdit}
                                        >
                                            {firstName.toUpperCase()}{' '}
                                            {lastName.toUpperCase()}
                                        </td>
                                    )}
                                    {tos ? (
                                        <td className="transaction-container__form--table-name">
                                            ACCEPTED
                                        </td>
                                    ) : (
                                        <td className="transaction-container__form--table-value">
                                            NOT ACCEPTED
                                        </td>
                                    )}
                                    <td
                                        className={
                                            standing === 'GOOD'
                                                ? 'transaction-container__form--table-name'
                                                : 'transaction-container__form--table-value'
                                        }
                                    >
                                        {standing}
                                    </td>
                                    <td className="transaction-container__form--table-value">
                                        {parseDate(
                                            entryDates !== undefined
                                                ? entryDates[
                                                      entryDates.length - 1
                                                  ]
                                                : new Date()
                                        )}
                                    </td>
                                    <td>
                                        <button
                                            className="user-button-icon"
                                            onClick={openModal}
                                        >
                                            <img
                                                className={_id}
                                                src={DeleteIcon}
                                                alt="delete Icon"
                                            />
                                        </button>
                                    </td>
                                </tr>
                            )
                        )}
                </tbody>
            </table>
        </div>
    );
};

export default GuestList;
