import GuestI from '../interfaces/Guest';
import getAge from './getAge';
import parseDate from './parseDate';
import AllAccessGuestI from '../interfaces/AllAccessGuest';
import AgeInput from '../interfaces/AgeInput';
import DateInput from '../interfaces/DateInput';

const getFilteredGuests = (
    filter: string,
    guests: GuestI[] | AllAccessGuestI[],
    input: string,
    ageInput: AgeInput,
    dateInput: DateInput
) => {
    switch (filter) {
        case 'guestID': {
            return guests.filter(
                ({ guestID }: GuestI) =>
                    input.trim() === '' || guestID.toLowerCase().includes(input)
            );
        }
        case 'keyword': {
            // eslint-disable-next-line
            return guests.filter((guest: GuestI) => {
                const guestKeys = Object.keys(guest);
                const skipKeys = [
                    '_id',
                    'entryDates',
                    'lifeTimeFees',
                    'lifeTimePoints',
                    'points',
                    'discount',
                    'weight',
                    'height',
                    'tos',
                    'lease',
                ];

                guest.accepted =
                    guest.tos && guest.lease ? 'ACCEPTED' : 'NOT ACCEPTED';

                for (const guestKey of guestKeys) {
                    if (skipKeys.includes(guestKey)) {
                        continue;
                    }

                    if (
                        input.trim() === '' ||
                        guest[guestKey].toLowerCase().includes(input)
                    ) {
                        return guest;
                    }
                }
            });
        }
        case 'age': {
            const { age1, age2 } = ageInput;

            return guests.filter(({ dateOfBirth }: GuestI) => {
                const guestAge = getAge(dateOfBirth as Date);

                if (age1 === null || age2 === null) {
                    return guests;
                }

                return guestAge >= age1 && guestAge <= age2;
            });
        }
        case 'stageName': {
            return guests.filter(
                ({ stageName }: GuestI | AllAccessGuestI) =>
                    input.trim() === '' ||
                    stageName.toLowerCase().includes(input)
            );
        }
        case 'fullName': {
            return guests.filter(({ firstName, lastName }: GuestI) => {
                const fullName = `${firstName}${lastName}`;

                return (
                    input.trim() === '' ||
                    fullName.toLowerCase().includes(input)
                );
            });
        }
        case 'date': {
            const { date1, date2 } = dateInput;

            const date1Unix = new Date(date1).getTime();
            const date2Unix = new Date(date2).getTime() + 86400000;
            // eslint-disable-next-line
            return guests.filter((guest: GuestI) => {
                if (guest.entryDates === undefined) {
                    return 'error';
                }

                const guestRecentEntry = new Date(
                    guest.entryDates[guest.entryDates.length - 1]
                );

                const guestRecentEntryUnix = guestRecentEntry.getTime();

                const hour = guestRecentEntry.getHours();

                if (
                    guestRecentEntryUnix >= date1Unix &&
                    guestRecentEntryUnix <= date2Unix
                ) {
                    if (
                        parseDate(guestRecentEntry) === parseDate(date1 as Date)
                    ) {
                        if (hour >= 11) {
                            return guest;
                        }
                    } else if (
                        parseDate(guestRecentEntry) === parseDate(date2 as Date)
                    ) {
                        if (hour <= 11) {
                            return guest;
                        }
                    }

                    return guest;
                }
            });
        }

        default: {
            return guests;
        }
    }
};

export default getFilteredGuests;
