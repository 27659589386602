import Container from '../../components/Container';
import Header from '../../components/Header';
import TransactionLookUpForm from '../../forms/TransactionLookUpForm';

const TransactionLookUpPage = () => (
    <Container containerName={'landing'}>
        <Header
            title={'Transaction Lookup'}
            subtitle={''}
            bottomBorder={true}
            padding={true}
        />
        <TransactionLookUpForm />
    </Container>
);

export default TransactionLookUpPage;
