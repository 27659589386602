import { useState } from 'react';
import Loading from '../components/Loading';
import FeeI from '../interfaces/Fee';
import FeeFormProps from '../interfaces/FeeFormProps';
import useDiscountFee from '../hooks/useDiscountFee';
import { SubmitHandler, useForm } from 'react-hook-form';
import { compact } from 'lodash';
import Form from '../components/Form';
import updateFeeByID from '../functions/updateFeeByID';

const FeeForm = ({ feeType, setAction }: FeeFormProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const { fees, points, setPoints } = useDiscountFee(feeType, setLoading, 0);
    const { register, handleSubmit } = useForm<FeeI>();

    const handleSave: SubmitHandler<FeeI> = async (data) => {
        if (!data) {
            setAction({ status: false, type: feeType });
            return;
        }

        const feesData = fees.map((fee) => {
            const dataKey = Object.keys(data).find((key) => key === fee._id);

            if (fee._id! === dataKey) {
                return {
                    day: fee.day,
                    _id: fee._id!,
                    value: data[dataKey] as number,
                };
            }

            return null;
        });

        await updateFeeByID(compact(feesData), feeType, setLoading, setAction);
    };

    if (loading || fees.length < 1) {
        return <Loading loading={loading} />;
    }

    return (
        <>
            <div>
                <h3 className="edit-fee-container__text">
                    You are editing {feeType}. Displayed current values.
                </h3>
            </div>

            <Form onSubmit={handleSubmit(handleSave)}>
                {feeType === 'AAEventFee' && (
                    <>
                        <span className="form-span">
                            Select points number to edit values
                        </span>
                        <select
                            className="select-input"
                            defaultValue={points}
                            onChange={(e) => setPoints(+e.target.value)}
                        >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </>
                )}

                {fees.map(
                    (fee: FeeI) =>
                        fee.points === points && (
                            <div key={fee._id}>
                                <span className="form-span">{fee.name}</span>
                                <input
                                    {...register(fee._id as string, {
                                        required: true,
                                        setValueAs: (value) =>
                                            parseInt(value, 10),
                                    })}
                                    className="form-input"
                                    type="text"
                                    defaultValue={fee.value}
                                    id={fee._id}
                                />
                            </div>
                        )
                )}
                <button className="green-button margin">Save</button>
                <button
                    className="red-button margin"
                    onClick={() =>
                        setAction({
                            status: false,
                            type: feeType,
                        })
                    }
                >
                    Cancel
                </button>
            </Form>
        </>
    );
};

export default FeeForm;
