import User from '../interfaces/User';
import AllAccessGuestI from '../interfaces/AllAccessGuest';
import axios from 'axios';

const sendEmailToGuest = async (
    guest: AllAccessGuestI,
    user: User,
    entryDate: Date = new Date()
) => {
    try {
        return await axios.post('/api/mailer/send', {
            guest,
            user,
            entryDate,
        });
    } catch (error) {
        console.log(error);
    }
};

export default sendEmailToGuest;
