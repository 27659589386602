import Message from './Message';

const AgeWarningMessage = ({ age }: { age: number }) => {
    if (age < 18) {
        return <Message message={'Guest is under 18!'} color={'red'} />;
    }

    if (age >= 18 && age < 21) {
        return <Message message={'Guest is under 21!'} color={'red'} />;
    }

    return <></>;
};

export default AgeWarningMessage;
