import TransactionI from '../interfaces/Transaction';
import axios from 'axios';
import GuestI from '../interfaces/Guest';

const editTransactionGuest = async (
    transaction: TransactionI,
    setGuest: (data: { guests: GuestI[]; page: string }) => void,
    history: any
) => {
    try {
        const { data } = await axios.get(
            `/api/guests/single?guestID=${transaction?.guestID}&type=${transaction?.type}`
        );

        setGuest({ ...data, page: history.location.pathname });
    } catch (error) {
        console.log(error);
    } finally {
        history.push(`/editGuest/${transaction?.type}`);
    }
};

export default editTransactionGuest;
