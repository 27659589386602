import { useEffect, useState } from 'react';
import axios from 'axios';

const useDiscount = (action: string, guestPoints: number = 0) => {
    const [discount, setDiscount] = useState(0);
    const [loadingDiscount, setLoading] = useState(true);
    const [points, setPoints] = useState(guestPoints);

    if (points > 5) {
        setPoints(5);
    }

    if (points < 0) {
        setPoints(0);
    }

    useEffect(() => {
        (async () => {
            if (action !== 'add') {
                return setLoading(false);
            }

            try {
                const { data } = await axios.get(
                    `/api/fees?type=OtherFee&points=${guestPoints}`
                );

                const hour = new Date().getHours();

                const [
                    earlyEvent,
                    earlyBird,
                    lateEvent,
                    lateEvent1,
                    lateEvent2,
                ] = data;

                if (hour >= 11 && hour < earlyBird.beforeHour) {
                    return setDiscount(earlyBird.value);
                }

                if (
                    hour >= earlyBird.beforeHour &&
                    hour < earlyEvent.beforeHour
                ) {
                    return setDiscount(earlyEvent.value);
                }

                if (
                    hour >= earlyEvent.beforeHour &&
                    hour < lateEvent.beforeHour
                ) {
                    return setDiscount(lateEvent.value);
                }

                if (
                    hour >= lateEvent.beforeHour &&
                    hour < lateEvent1.beforeHour
                ) {
                    return setDiscount(lateEvent1.value);
                }

                return setDiscount(lateEvent2);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        })();
    }, [action, guestPoints]);

    return {
        discount,
        setDiscount,
        loadingDiscount,
    };
};

export default useDiscount;
