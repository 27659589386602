import { useEffect, useState } from 'react';
import FeeI from '../interfaces/Fee';
import axios from 'axios';

const useDiscountFee = (
    feeType: string,
    setLoading: (value: boolean) => void,
    guestPoints: number = 0
) => {
    const [fees, setFees] = useState<FeeI[]>([]);
    const [points, setPoints] = useState(guestPoints);

    useEffect(() => {
        (async () => {
            setLoading(true);

            try {
                const { data } = await axios.get(
                    `/api/fees?type=${feeType}&points=${points}`
                );
                setFees(data);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [points]);

    return { fees, setFees, points, setPoints };
};

export default useDiscountFee;
