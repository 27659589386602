import GuestI from '../interfaces/Guest';
import { omit } from 'lodash';
import axios from 'axios';
import getGuestID from './getGuestID';

const updateGuest = async (
    guest: GuestI,
    data: GuestI,
    setLoading: (value: boolean) => void,
    history: any
) => {
    setLoading(true);
    try {
        const emptyProperties = Object.keys(guest).filter(
            (key) => guest[key] === ''
        );

        const dataGuest = {
            ...omit(data, emptyProperties),
            guestID: getGuestID({
                firstName: data.firstName,
                lastName: data.lastName,
                licenseID: data.licenseID,
            }).toLowerCase(),
        };

        await axios.patch(`/api/guests/${guest._id}?type=${guest.type}`, {
            ...dataGuest,
        });
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
        history.push(guest?.page as string);
    }
};

export default updateGuest;
