import { Link, useHistory } from 'react-router-dom';
import SignaturePad from 'react-signature-canvas';
import { MouseEvent, useState } from 'react';
import updateGuestSignature from '../functions/updateGuestSignature';
import GuestI from '../interfaces/Guest';
import Button from './Button';

const Agreement = ({
    guest,
    setLoading,
}: {
    guest: GuestI;
    setLoading: (value: boolean) => void;
}) => {
    const [checkbox, setCheckbox] = useState<boolean>(true);
    const history = useHistory();
    let sigPad: any = {};

    const handleUpdateGuest = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        await updateGuestSignature(
            guest,
            sigPad,
            history,
            setLoading,
            true,
            checkbox
        );
    };

    return (
        <div className="agree">
            <input
                type="checkbox"
                defaultChecked={checkbox}
                onClick={(e) => setCheckbox(e.currentTarget.checked)}
            />
            <span className="checkbox-span">
                I agree to{' '}
                <Link to="/tos" target="_blank">
                    terms and conditions
                </Link>{' '}
                and{' '}
                <Link to="/lease" target="_blank">
                    lease agreement
                </Link>{' '}
                by checking this box.
            </span>

            <div className="sig-container margin">
                <h3>Sign here</h3>
                <SignaturePad
                    canvasProps={{ className: 'sig-pad' }}
                    ref={(ref) => {
                        sigPad = ref;
                    }}
                />
            </div>
            <div>
                <Button
                    color={'green'}
                    margin={true}
                    onClick={handleUpdateGuest}
                >
                    Continue
                </Button>
            </div>
            <div>
                <Button
                    color={'red'}
                    margin={true}
                    onClick={() => sigPad.clear()}
                >
                    Clear
                </Button>
            </div>
        </div>
    );
};

export default Agreement;
