import AllAccessGuestI from '../interfaces/AllAccessGuest';
import axios from 'axios';

const activateAccount = async (
    { _id, type, guestID, firstName, lastName, stageName }: AllAccessGuestI,
    feeType: string
) => {
    const guestPoints = 1;
    try {
        const { data } = await axios.patch(`/api/guests/${_id}?type=${type}`, {
            active: true,
            points: guestPoints,
        });

        await axios.post('/api/transactions', {
            guestID: guestID,
            firstName: firstName,
            lastName: lastName,
            stageName: stageName || 'NONE',
            fee: feeType,
            type: type,
            value: 100,
            points: guestPoints,
        });

        return data;
    } catch (error) {
        console.log(error);
    }
};

export default activateAccount;
