import axios from 'axios';

const deleteUser = async (
    id: string,
    setIsOpen: any,
    setLoading: any,
    setAction: any
) => {
    setLoading(true);
    setIsOpen(false);
    try {
        await axios.delete(`/api/users?id=${id}`);
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
        setAction({ status: false, type: 'delete' });
    }
};

export default deleteUser;
