import { ChangeEvent, useState } from 'react';
import axios from 'axios';
import User from '../interfaces/User';
import UserFormI from '../interfaces/UserForm';
import { SubmitHandler, useForm } from 'react-hook-form';
import validator from 'validator';
import Form from '../components/Form';
import Button from '../components/Button';
import Text from '../components/Text';

const UserForm = ({ action, setAction, setLoading, user }: UserFormI) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        getValues,
    } = useForm<User>();
    const [passwordEqual, setPasswordEqual] = useState<boolean>(false);
    const [passwordActive, setPasswordActive] = useState<boolean>(false);

    const createUser = async (data: User) => {
        setLoading(true);
        try {
            await axios.post('/api/users/create', {
                ...data,
            });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            setAction({ status: false, type: action });
        }
    };

    const updateUser = async (data: User) => {
        setLoading(true);
        try {
            await axios.patch(`/api/users/?id=${user?._id}`, {
                ...data,
            });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            setAction({ status: false, type: action });
        }
    };

    const handlePassword = (e: ChangeEvent<HTMLInputElement>) => {
        const password = getValues('password');
        const isPasswordEqual =
            e.target.value === password && password.length >= 7;

        setPasswordActive(true);

        return setPasswordEqual(isPasswordEqual);
    };

    const handleUser: SubmitHandler<User> = async (data) => {
        delete data.confirmPassword;
        if (!passwordActive) {
            delete data.password;
        }

        if (action === 'create') {
            return await createUser(data);
        }

        return await updateUser(data);
    };

    return (
        <Form onSubmit={handleSubmit(handleUser)}>
            {!passwordEqual && passwordActive && (
                <Text>
                    Passwords do not match or their length is less than 7!
                </Text>
            )}
            <div>
                <span className="form-span">Set Login: </span>
                {errors.login?.type === 'required' && (
                    <p className="landing-container__warning">
                        This field is required
                    </p>
                )}
                {errors.login?.type === 'maxLength' && (
                    <p className="landing-container__warning">
                        Username input is too long
                    </p>
                )}
                {errors.login?.type === 'minLength' && (
                    <p className="landing-container__warning">
                        Username input is too short
                    </p>
                )}
                <input
                    {...register('login', {
                        required: true,
                        maxLength: 16,
                        minLength: 2,
                        setValueAs: (v: string) => v.toLowerCase(),
                    })}
                    className="form-input"
                    type="text"
                    defaultValue={user?.login}
                    autoComplete="username"
                />
            </div>
            <div>
                <span className="form-span">Set Password: </span>
                {errors.password?.type === 'required' && (
                    <p className="landing-container__warning">
                        This field is required
                    </p>
                )}
                {errors.password?.type === 'maxLength' && (
                    <p className="landing-container__warning">
                        Password is too long
                    </p>
                )}
                {errors.password?.type === 'minLength' && (
                    <p className="landing-container__warning">
                        Password is too short
                    </p>
                )}
                <input
                    {...register('password', {
                        required: action === 'create' || passwordActive,
                        maxLength: 64,
                        minLength: 7,
                    })}
                    className="form-input"
                    type="password"
                    autoComplete="new-password"
                />
            </div>
            <div>
                <span className="form-span">Confirm Password: </span>
                {errors.confirmPassword?.type === 'required' && (
                    <p className="landing-container__warning">
                        This field is required
                    </p>
                )}
                {errors.confirmPassword?.type === 'maxLength' && (
                    <p className="landing-container__warning">
                        Password is too long
                    </p>
                )}
                {errors.confirmPassword?.type === 'minLength' && (
                    <p className="landing-container__warning">
                        Password is too short
                    </p>
                )}
                <input
                    {...register('confirmPassword', {
                        required: action === 'create' || passwordActive,
                        maxLength: 64,
                        minLength: 7,
                    })}
                    className="form-input"
                    type="password"
                    autoComplete="new-password"
                    onChange={handlePassword}
                />
            </div>
            <div>
                <span className="form-span">First Name: </span>
                {errors.firstName?.type === 'required' && (
                    <p className="landing-container__warning">
                        This field is required
                    </p>
                )}
                {errors.firstName?.type === 'maxLength' && (
                    <p className="landing-container__warning">
                        First Name is too long
                    </p>
                )}
                {errors.firstName?.type === 'minLength' && (
                    <p className="landing-container__warning">
                        First Name is too short
                    </p>
                )}
                <input
                    {...register('firstName', {
                        required: true,
                        maxLength: 32,
                        minLength: 2,
                    })}
                    type="text"
                    className="form-input"
                    defaultValue={user?.firstName}
                />
            </div>
            <div>
                <span className="form-span">Last Name: </span>
                {errors.lastName?.type === 'required' && (
                    <p className="landing-container__warning">
                        This field is required
                    </p>
                )}
                {errors.lastName?.type === 'maxLength' && (
                    <p className="landing-container__warning">
                        Last Name is too long
                    </p>
                )}
                {errors.lastName?.type === 'minLength' && (
                    <p className="landing-container__warning">
                        Last Name is too short
                    </p>
                )}
                <input
                    {...register('lastName', {
                        required: true,
                        maxLength: 64,
                        minLength: 2,
                    })}
                    type="text"
                    className="form-input"
                    defaultValue={user?.lastName}
                />
            </div>
            <div>
                <span className="form-span">E-mail: </span>
                {errors.email?.type === 'required' && (
                    <p className="landing-container__warning">
                        This field is required
                    </p>
                )}
                {errors.email?.type === 'validate' && (
                    <p className="landing-container__warning">
                        Email is invalid
                    </p>
                )}
                <input
                    {...register('email', {
                        required: true,
                        maxLength: 55,
                        validate: (value: string) => validator.isEmail(value),
                    })}
                    type="email"
                    className="form-input"
                    defaultValue={user?.email}
                />
            </div>
            <div>
                <span className="form-span">Access Level: </span>
                {errors.accessLevel?.type === 'required' && (
                    <p className="landing-container__warning">
                        This field is required
                    </p>
                )}
                {errors.accessLevel?.type === 'validate' && (
                    <p className="landing-container__warning">
                        Access Level is missing
                    </p>
                )}
                <select
                    {...register('accessLevel', {
                        required: true,
                        validate: (value) => value >= 1 && value <= 3,
                        setValueAs: (v: string) => parseInt(v),
                    })}
                    className="form-input"
                    defaultValue={user?.accessLevel ?? 'default'}
                >
                    <option value="default">Select Access Level...</option>
                    <option value="1">Access Level 1</option>
                    <option value="2">Access Level 2</option>
                    <option value="3">Admin</option>
                </select>
            </div>
            <Button color={'green'} margin={true}>
                Confirm
            </Button>
            <Button
                color={'red'}
                margin={true}
                onClick={() => setAction({ status: false, type: action })}
            >
                Go Back
            </Button>
        </Form>
    );
};

export default UserForm;
