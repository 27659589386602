const parseDate = (date: Date = new Date()) => {
    date = new Date(date);

    const month =
        date.getMonth().toString().length === 1
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1;
    const day =
        date.getDate().toString().length === 1
            ? '0' + date.getDate()
            : date.getDate();

    return `${month}-${day}-${date.getFullYear()}`;
};

export default parseDate;
