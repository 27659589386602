import TransactionsI from '../interfaces/Transactions';

const getFees = (type: string, transactions: TransactionsI) => {
    let total: number = 0;

    switch (type) {
        case 'allAccess': {
            for (const fee of transactions.allAccess) {
                total += fee.value;
            }
            break;
        }
        case 'limitedAccess': {
            for (const fee of transactions.limited) {
                total += fee.value;
            }
            break;
        }
        case 'total': {
            const allAccessTotal = getFees('allAccess', transactions);
            const limitedTotal = getFees('limitedAccess', transactions);

            total = allAccessTotal + limitedTotal;
            break;
        }

        default: {
            return 0;
        }
    }

    return total;
};

export default getFees;
