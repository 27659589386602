import Button from '../components/Button';
import updateGuestPoints from '../functions/updateGuestPoints';
import FeeI from '../interfaces/Fee';
import GuestI from '../interfaces/Guest';
import AllAccessGuestI from '../interfaces/AllAccessGuest';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import Form from '../components/Form';
import { useState } from 'react';
import UpdateGuestModal from '../components/modals/UpdateGuestModal';
import User from '../interfaces/User';
import sendEmailToGuest from '../functions/sendEmailToGuest';
import useFee from '../hooks/useFee';
import Loading from '../components/Loading';
import useDiscount from '../hooks/useDiscount';

const UpdateGuestForm = ({
    guest,
    setGuest,
    user,
    setLoading,
}: {
    guest: AllAccessGuestI;
    setGuest: (guest: AllAccessGuestI) => void;
    user: User;
    setLoading: (value: boolean) => void;
}) => {
    const [feeType] = useState<string>(
        guest?.type === 'allAccess' ? 'AAEventFee' : 'EventFee'
    );
    const { fee, loadingFee, setGuestPoints } = useFee(
        feeType,
        guest as GuestI
    );
    const { discount } = useDiscount('add', guest?.points || 0);
    const [renewalStatus, setRenewalStatus] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<FeeI>();

    const handleUpdateGuestPoints: SubmitHandler<FeeI> = async (data) => {
        if (!guest.active && !renewalStatus && guest.type === 'allAccess') {
            return setIsOpen(true);
        }

        const dataArr = Object.values(data);

        const value = dataArr[dataArr.length - 1];

        let modified = false;

        const feeData: FeeI = {
            value,
            day: fee?.day!,
            name: fee?.name!,
        };

        if (value !== fee?.value) {
            modified = true;
        }

        await updateGuestPoints(
            feeType,
            feeData,
            guest as GuestI | AllAccessGuestI,
            setLoading,
            history,
            modified,
            renewalStatus,
            discount
        );

        await sendEmailToGuest(guest, user);
    };

    if (loadingFee) {
        return <Loading loading={loadingFee} />;
    }

    return (
        <Form onSubmit={handleSubmit(handleUpdateGuestPoints)}>
            <div>
                <span className="form-span">Today's fee:</span>
                {errors.value?.type === 'required' && (
                    <p className="landing-container__warning">
                        This field is required
                    </p>
                )}
                <input
                    {...register(fee?._id as string, {
                        required: true,
                        setValueAs: (value) => parseInt(value, 10),
                    })}
                    className="form-input"
                    type="text"
                    defaultValue={fee?.value}
                />
            </div>

            <Button color={'green'} margin={true}>
                Check In
            </Button>
            <Button
                color={'red'}
                margin={true}
                onClick={() => history.push('/dashboard')}
            >
                Go back
            </Button>
            <UpdateGuestModal
                guest={guest}
                setGuest={setGuest}
                setRenewalStatus={setRenewalStatus}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                feeType={feeType}
                setGuestPoints={setGuestPoints}
            />
        </Form>
    );
};

export default UpdateGuestForm;
