const SelectFee = ({
    feeType,
    setFeeType,
}: {
    feeType: string;
    setFeeType: (value: string) => void;
}) => {
    return (
        <select
            className="select-input"
            defaultValue={feeType}
            onChange={(e) => setFeeType(e.target.value)}
        >
            <option value="default">Select fee...</option>
            <option value="EventFee">Event Fee</option>
            <option value="AAEventFee">All Access Event Fee</option>
            <option value="OtherFee">Additional Fees</option>
        </select>
    );
};

export default SelectFee;
