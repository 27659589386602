import { useEffect, useState } from 'react';
import axios from 'axios';
import AllAccessGuestI from '../interfaces/AllAccessGuest';

const useSignature = ({ guestID, type }: AllAccessGuestI) => {
    const [signature, setSignature] = useState(false);
    const [loadingSignature, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axios.get(
                    `/api/signatures/${guestID}/${type}`
                );

                if (!data.file) {
                    return setSignature(false);
                }

                return setSignature(true);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        })();
    }, [guestID, type]);

    return {
        signature,
        setSignature,
        loadingSignature,
    };
};

export default useSignature;
