import GuestI from '../interfaces/Guest';
import AllAccessGuestI from '../interfaces/AllAccessGuest';
import AgeInput from '../interfaces/AgeInput';
import DateInput from '../interfaces/DateInput';
import { useEffect, useState } from 'react';
import getFilteredGuests from '../functions/getFilteredGuests';

const useFilteredGuests = (
    filter: string,
    guests: GuestI[] | AllAccessGuestI[],
    input: string,
    ageInput: AgeInput,
    dateInput: DateInput
) => {
    const [filteredGuests, setFilteredGuests] = useState<GuestI[]>([]);

    useEffect(() => {
        setFilteredGuests(
            getFilteredGuests(filter, guests, input, ageInput, dateInput)
        );
    }, [filter, guests, input, ageInput, dateInput]);

    return { filteredGuests, setFilteredGuests };
};

export default useFilteredGuests;
