import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import GlobalContext from '../contexts/GlobalContext';
import GlobalContextI from '../interfaces/GlobalContext';
import DashboardPanel from '../components/DashboardPanel';
import dropToken from '../functions/dropToken';
import Container from '../components/Container';
import Button from '../components/Button';
import Header from '../components/Header';

const DashboardPage = () => {
    const { user, setUser } = useContext<GlobalContextI>(GlobalContext);
    const [level] = useState(
        user.accessLevel === 3 ? 'ADMIN' : `Level ${user?.accessLevel}`
    );
    const history = useHistory();

    const handleLogOut = async () => await dropToken(setUser!, history);

    return (
        <Container containerName={'dashboard'}>
            <Header
                title={`Welcome ${user.firstName}! ${level}`}
                subtitle={''}
                bottomBorder={true}
            />

            <DashboardPanel accessLevel={user.accessLevel} />

            <Button color={'red'} margin={true} onClick={handleLogOut}>
                Log Out
            </Button>
        </Container>
    );
};

export default DashboardPage;
