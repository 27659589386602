const Lease = () => {
    return (
        <div className="tos-page">
            <header>
                <h1>Lease agreement</h1>
            </header>
            <p>
                <b>
                    WHEREAS, Licensee seeks to acquire the right to enter upon
                    Licensor’s property located at 1837 N 1500 Road, Douglas
                    County, KS(the "Premises"), NOW, THEREFORE, intending to be
                    legally bound, the parties agree as follows:
                </b>
            </p>
            <p>
                1. Use. Licensor hereby grants to Licensee, a non-exclusive,
                revocable License to enter upon and use the Premises and the
                right of ingress and egress to and from the Premises, subject to
                the terms and conditions herein, for the purpose of using the
                Outhouse Facilities to lawfully assemble, as allowed by the
                FIRST amendment of The US constitution. _(“License”).
            </p>
            <p>
                2. Term. This License shall commence upon the execution date and
                shall continue til the facility premises close for the same day.
                At the expiration or earlier termination of this License,
                Licensee shall immediately cease use of the Premises.
            </p>
            <p>
                3. Consideration. As total consideration for this License,
                Licensee shall pay to Licensor $10 per Day, Sunday through
                Thursday or $20 per day Friday and Saturday.
            </p>
            <p>
                4. Conditions Applicable to License. This License is subject to
                all existing covenants, conditions, reservations, contracts,
                leases, licenses, easements, encumbrances, restrictions and
                rights of way with respect to the Premises, whether or not of
                record.
            </p>
            <p>
                5. No Transfer or Assignment. This License is personal to
                Licensee. Any attempt to transfer or assign this License shall
                terminate it.
            </p>
            <p>
                6. Permits and Regulations. Licensee shall be responsible for
                securing any required approvals, permits and authorizations from
                any federal, state or local agencies and shall comply with all
                applicable laws and regulations.
            </p>
            <p>
                7. No Interference. Licensee shall not interfere with the normal
                operation and activities of Licensor, and Licensee shall conduct
                its activities on the Premises to minimize damage to the
                Premises and inconvenience to Licensor, its agents, other
                licensees, employees and invitees.
            </p>
            <p>
                8. Repair and Restoration. If Licensee or its agents or cause
                any damage to the Premises, or to Licensor's roads,
                infrastructure or other property and improvements (collectively
                “Property”) in connection with the exercise of this License,
                Licensee shall Pay Licensor for the amount to restore the damage
                caused by Licensee.
            </p>
            <p>
                9. Breach and Cure. In the event that Licensee breaches any of
                its obligations under this License, or fail to comply with the
                terms and conditions of the this license, including the TOS
                agreement acknowledged at the time of consenting to this
                agreement, Licensor shall have the right to immediately
                terminate this License agreement without explanation or refund
                of facility use fees paid by Licensee. If Licensee fails to pay
                for any damage caused by licensee or it’s agents to the
                premises, Licensor shall have all rights and remedies available
                under Kansas law including, but not limited to, actions for
                damages and specific performance, for any breach of Licensee’s
                obligations hereunder.
            </p>
            <p>
                10. Alteration in Writing. This License supersedes any and all
                prior understandings and agreements, whether written or oral,
                between the parties with respect to the subject matter of this
                License. No alteration or variation of this License shall be
                valid unless made in writing and signed by Licensor and
                Licensee.
            </p>
            <p>
                11. Notice. Any notice required hereunder shall be in writing
                and shall be addressed as follows:
            </p>
            <p>
                or to such other address as either party may indicate in a
                written notice to the other. All notices and communications
                given under this License Agreement shall be deemed to have been
                duly given and received: (i) upon personal delivery, or (ii) as
                of the third business day after mailing by United States
                certified mail, return receipt requested, postage prepaid,
                addressed as set forth above, or (iii) the immediately
                succeeding business day after deposit (for next day delivery)
                with Federal Express or other similar overnight courier system,
                or (iv) 24 hours after facsimile transmittal with confirmation
                of receipt and followed by personal delivery, United States
                mail, or overnight delivery as specified in this Paragraph.
            </p>

            <p>12. Indemnification.</p>
            <p>
                Licensee shall indemnify, defend, and hold harmless Licensor,
                its officers, agents and employees, from and against any claims,
                damages, costs, expenses, or liabilities (collectively “Claims”)
                arising out of or in any way connected with this License
                including, without limitation, Claims for loss or damage to any
                property, or for death or injury to any person or persons but
                only in proportion to and to the extent that such Claims arise
                from the negligent or intentional acts or omissions of Licensee,
                its officers, agents, partners, invitees or employees.{' '}
            </p>
            <p>13. Insurance.</p>
            <p>
                13.1 Licensee’s Insurance. Licensee, at its sole cost and
                expense, shall insure its activities in connection with this
                License and obtain, keep in force, and maintain insurance as
                follows:
            </p>
            <br></br>
            <p>
                1. Commercial Form General Liability Insurance (contractual
                liability included) with minimum limits as follows:
            </p>
            <p>a. Each Occurrence $1,000,000</p>
            <p>b. Products/Completed Operations Aggregate $1,000,000</p>
            <p>c. Personal and Advertising Injury $1,000,000</p>
            <p>d. General Aggregate $2,000,000</p>
            <p>
                e. If the above insurance is written on a claims-made form, it
                shall continue for three (3) years following termination of this
                License. The insurance shall have a retroactive date of
                placement prior to or coinciding with the commencement of the
                Term of this License.
            </p>
            <p>
                2. Business Automobile Liability Insurance for owned, scheduled,
                non-owned, or hired automobiles with a combined single of not
                less than Three hundred thousand dollars ($300,000) per
                occurrence.
            </p>
            <p>
                3. Property Insurance, Fire and Extended Coverage Form in an
                amount sufficient to reimburse Licensee for all of its
                equipment, trade fixtures, inventory, fixtures and other
                personal property located on or in the Premises including
                leasehold improvements hereinafter constructed or installed.
            </p>
            <p>4. Workers' Compensation as required by Kansas law.</p>
            <p>
                5. Such other insurance in such amounts which from time to time
                may be reasonably required by the mutual consent of Licensor and
                Licensee against other insurable risks relating to performance.
            </p>
            <p>
                The coverages required herein shall not limit the liability of
                Licensee.
            </p>
            <p>
                The coverages referred to under 1. and 2. of this Section 13.1
                shall include Licensor as an additional insured. Such a
                provision shall apply only in proportion to and to the extent of
                the negligent acts or omissions of Licensee, its officers,
                agents, and employees. Licensee, upon the execution of this
                License, shall furnish Licensor with certificates of insurance
                evidencing compliance with all requirements. Certificates shall
                provide for thirty (30) days (ten [10] days for non-payment of
                premium) advance written notice to Licensor of any material
                modification, change or cancellation of the above insurance
                coverages.
            </p>
            <p>13.2 Waiver of Subrogation.</p>
            <p>
                Licensee hereby waives any right of recovery against Licensor
                due to loss of or damage to the property of Licensee when such
                loss of or damage to property arises out of an act of God or any
                of the property perils included in the classification of fire or
                extended perils (“all risk” as such term is used in the
                insurance industry) whether or not such perils have been
                insured, self-insured, or non-insured.
            </p>
            <p>
                optional: for use where a reciprocal insurance requirement is
                appropriate and necessary--not appropriate where licensee gives
                a unilateral indemnity under Section 12 above and not
                automatically necessary even in cases where reciprocal indemnity
                is required—Add Subparagraph as follows:
            </p>
            <p>
                13.3 Licensor's Insurance. Licensor will maintain a program of
                self-insurance.
            </p>
            <p>
                14. Lien Free Condition. Licensee shall not cause or permit any
                liens to be placed against the Premises or against Licensor's
                other property as a result of Licensee's exercise of rights
                under this License. In the event of the filing of any such
                liens, Licensee shall promptly cause such liens to be removed.
                In no event shall such lien removal require more than thirty
                (30) days.
            </p>
            <p>
                15. Unenforceable Provisions. The invalidity or unenforceability
                of any provision hereof shall not effect or impair the validity
                of any other provision{' '}
            </p>
        </div>
    );
};

export default Lease;
