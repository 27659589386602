import { SubmitHandler, useForm } from 'react-hook-form';
import LoginFormI from '../interfaces/LoginForm';
import { useState } from 'react';
import axios from 'axios';
import User from '../interfaces/User';
import Button from '../components/Button';
import Form from '../components/Form';

const LoginForm = ({ setUser }: { setUser: any }) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<LoginFormI>();
    const [error, setError] = useState(false);

    const loginUser: SubmitHandler<LoginFormI> = async ({
        login,
        password,
    }) => {
        try {
            const { data } = await axios.post('/api/auth/login', {
                login: login,
                password: password,
            });

            if (setUser) {
                setUser(data.user as User);
            }
        } catch (error) {
            console.log(error);
            setError(true);
        }
    };

    return (
        <Form onSubmit={handleSubmit(loginUser)}>
            {error && (
                <p className="landing-container__warning">
                    You have provided wrong credentials!
                </p>
            )}
            <div>
                <span className="form-span">Login</span>
                {errors.login?.type === 'required' && (
                    <p className="landing-container__warning">
                        This field is required!
                    </p>
                )}
                {errors.login?.type === 'minLength' && (
                    <p className="landing-container__warning">
                        Username is too short!
                    </p>
                )}
                {errors.login?.type === 'maxLength' && (
                    <p className="landing-container__warning">
                        Username is too long!
                    </p>
                )}
                <input
                    {...register('login', {
                        required: true,
                        maxLength: 16,
                        minLength: 2,
                    })}
                    type="text"
                    placeholder="enter your username..."
                    autoComplete="username"
                    className="form-input"
                />
            </div>

            <div>
                <span className="form-span">Password</span>
                {errors.password?.type === 'required' && (
                    <p className="landing-container__warning">
                        This field is required!
                    </p>
                )}
                {errors.password?.type === 'minLength' && (
                    <p className="landing-container__warning">
                        Password is too short!
                    </p>
                )}
                {errors.password?.type === 'maxLength' && (
                    <p className="landing-container__warning">
                        Password is too long!
                    </p>
                )}
                <input
                    {...register('password', {
                        required: true,
                        maxLength: 64,
                        minLength: 8,
                    })}
                    type="password"
                    placeholder="enter your password..."
                    autoComplete="current-password"
                    className="form-input"
                />
            </div>
            <Button color={'green'} margin={true}>
                Sign In
            </Button>
        </Form>
    );
};

export default LoginForm;
