import TransactionI from '../interfaces/Transaction';
import { SubmitHandler, useForm } from 'react-hook-form';
import updateTransaction from '../functions/updateTransaction';
import Action from '../interfaces/Action';
import Form from '../components/Form';
import Button from '../components/Button';

const TransactionForm = ({
    transaction,
    setAction,
    setLoading,
}: {
    transaction: TransactionI | null;
    setAction: (action: Action) => void;
    setLoading: (value: boolean) => void;
}) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<TransactionI>();

    const handleUpdateTransaction: SubmitHandler<TransactionI> = async (
        data
    ) => {
        if (!transaction) {
            return;
        }

        return await updateTransaction(
            transaction._id!,
            data,
            setLoading,
            setAction
        );
    };

    return (
        <Form onSubmit={handleSubmit(handleUpdateTransaction)}>
            <div>
                <span className="form-span">Date: </span>
                {errors.date?.type === 'required' && (
                    <p className="landing-container__warning">
                        This field is required
                    </p>
                )}
                {errors.date?.type === 'maxLength' && (
                    <p className="landing-container__warning">
                        This field is too long
                    </p>
                )}
                <input
                    {...register('date', { required: true, maxLength: 32 })}
                    className="form-input"
                    type="text"
                    defaultValue={
                        transaction ? (transaction.date as string) : ''
                    }
                />
            </div>
            <div>
                <span className="form-span">guestID: </span>
                {errors.guestID?.type === 'required' && (
                    <p className="landing-container__warning">
                        This field is required
                    </p>
                )}
                {errors.guestID?.type === 'maxLength' && (
                    <p className="landing-container__warning">
                        This field is too long
                    </p>
                )}
                <input
                    {...register('guestID', {
                        required: true,
                        maxLength: 32,
                    })}
                    className="form-input"
                    type="text"
                    defaultValue={transaction?.guestID}
                />
            </div>
            <div>
                <span className="form-span">First Name: </span>
                {errors.firstName?.type === 'required' && (
                    <p className="landing-container__warning">
                        This field is required
                    </p>
                )}
                {errors.firstName?.type === 'maxLength' && (
                    <p className="landing-container__warning">
                        This field is too long
                    </p>
                )}
                <input
                    {...register('firstName', {
                        required: true,
                        maxLength: 32,
                    })}
                    className="form-input"
                    type="text"
                    defaultValue={transaction?.firstName}
                />
            </div>
            <div>
                <span className="form-span">Last Name: </span>
                {errors.lastName?.type === 'required' && (
                    <p className="landing-container__warning">
                        This field is required
                    </p>
                )}
                {errors.lastName?.type === 'maxLength' && (
                    <p className="landing-container__warning">
                        This field is too long
                    </p>
                )}
                <input
                    {...register('lastName', {
                        required: true,
                        maxLength: 64,
                    })}
                    className="form-input"
                    type="text"
                    defaultValue={transaction?.lastName}
                />
            </div>
            <div>
                <span className="form-span">Stage Name: </span>
                {errors.stageName?.type === 'required' && (
                    <p className="landing-container__warning">
                        This field is required
                    </p>
                )}
                {errors.stageName?.type === 'maxLength' && (
                    <p className="landing-container__warning">
                        This field is too long
                    </p>
                )}
                <input
                    {...register('stageName', {
                        required: true,
                        maxLength: 64,
                    })}
                    type="text"
                    className="form-input"
                    defaultValue={transaction?.stageName}
                />
            </div>
            <div>
                <span className="form-span">Fee: </span>
                {errors.fee?.type === 'required' && (
                    <p className="landing-container__warning">
                        This field is required
                    </p>
                )}
                {errors.fee?.type === 'maxLength' && (
                    <p className="landing-container__warning">
                        This field is too long
                    </p>
                )}
                <select
                    {...register('fee', {
                        required: true,
                        validate: (value) => value !== 'default',
                        maxLength: 32,
                    })}
                    className="form-input"
                    defaultValue={transaction?.fee}
                >
                    <option value="default">Select Fee Type...</option>
                    <option value="EventFee">Limited Event Fee</option>
                    <option value="AAEventFee">All Access Event Fee</option>
                </select>
            </div>
            <div>
                <span className="form-span">Access Level: </span>
                {errors.type?.type === 'required' && (
                    <p className="landing-container__warning">
                        This field is required
                    </p>
                )}
                {errors.type?.type === 'maxLength' && (
                    <p className="landing-container__warning">
                        This field is too long
                    </p>
                )}
                <select
                    {...register('type', {
                        required: true,
                        validate: (value) => value !== 'default',
                        maxLength: 32,
                    })}
                    className="form-input"
                    defaultValue={transaction?.type}
                >
                    <option value="default">Select Access Level...</option>
                    <option value="limited">Limited Access</option>
                    <option value="allAccess">All Access</option>
                </select>
            </div>
            <div>
                <span className="form-span">Value: </span>
                {errors.value?.type === 'required' && (
                    <p className="landing-container__warning">
                        This field is required
                    </p>
                )}
                {errors.value?.type === 'maxLength' && (
                    <p className="landing-container__warning">
                        This field is too long
                    </p>
                )}
                <input
                    {...register('value', {
                        required: true,
                        maxLength: 32,
                        setValueAs: (value) =>
                            value.length >= 1 ? parseInt(value, 10) : 0,
                    })}
                    type="text"
                    className="form-input"
                    defaultValue={transaction?.value}
                />
            </div>
            <div>
                <span className="form-span">Points: </span>
                {errors.points?.type === 'required' && (
                    <p className="landing-container__warning">
                        This field is required
                    </p>
                )}
                {errors.points?.type === 'maxLength' && (
                    <p className="landing-container__warning">
                        This field is too long
                    </p>
                )}
                <input
                    {...register('points', {
                        required: true,
                        maxLength: 32,
                        setValueAs: (value) =>
                            value.length >= 1 ? parseInt(value, 10) : 0,
                    })}
                    type="text"
                    className="form-input"
                    defaultValue={transaction?.points}
                />
            </div>
            <Button color={'green'} margin={true}>
                Confirm
            </Button>
            <Button
                color={'red'}
                margin={true}
                onClick={() => setAction({ status: false, type: 'edit' })}
            >
                Go Back
            </Button>
        </Form>
    );
};

export default TransactionForm;
