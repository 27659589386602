import SignaturePad from 'react-signature-canvas';
import { MouseEvent, useContext, useState } from 'react';
import updateGuestSignature from '../functions/updateGuestSignature';
import Loading from './Loading';
import GlobalContextI from '../interfaces/GlobalContext';
import GlobalContext from '../contexts/GlobalContext';
import { useHistory } from 'react-router-dom';
import FlexContainer from './FlexContainer';
import Button from './Button';

const GuestSignature = () => {
    const { guest } = useContext<GlobalContextI>(GlobalContext);
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();
    let sigPad: any = {};

    const handleUpdateGuest = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        await updateGuestSignature(guest, sigPad, history, setLoading, false);
    };

    if (loading) {
        return <Loading loading={loading} />;
    }

    return (
        <FlexContainer>
            <div className="agree">
                <div className="notice-guest-container__text">
                    <h3>
                        {guest?.guestID}'s signature hasn't been found, to
                        proceed further a guest has to sign canvas below.
                    </h3>
                </div>
                <div className="sig-container margin">
                    <h3>Sign here</h3>
                    <SignaturePad
                        canvasProps={{ className: 'sig-pad' }}
                        ref={(ref) => {
                            sigPad = ref;
                        }}
                    />
                </div>
                <div>
                    <Button
                        color={'green'}
                        margin={true}
                        onClick={handleUpdateGuest}
                    >
                        Continue
                    </Button>
                </div>
                <div>
                    <Button
                        color={'red'}
                        margin={true}
                        onClick={() => sigPad.clear()}
                    >
                        Clear
                    </Button>
                </div>
            </div>
        </FlexContainer>
    );
};

export default GuestSignature;
