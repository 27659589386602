import Header from '../../components/Header';
import Container from '../../components/Container';
import TosGuestConfirm from '../../components/TosGuestConfirm';

const TosGuestPage = () => {
    return (
        <Container containerName={'landing'}>
            <Header
                title={'Terms of Service'}
                subtitle={'Please read the following:'}
            />
            <TosGuestConfirm />
        </Container>
    );
};

export default TosGuestPage;
