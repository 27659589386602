import { ReactNode } from 'react';

const Form = ({
    children,
    onSubmit,
}: {
    children: ReactNode;
    onSubmit?: (value: any) => void;
}) => (
    <form className="landing-container__form" onSubmit={onSubmit}>
        {children}
    </form>
);

export default Form;
