import axios from 'axios';
import User from '../interfaces/User';
import { Dispatch, SetStateAction } from 'react';

const dropToken = async (
    setUser: Dispatch<SetStateAction<User | null>>,
    history: any
) => {
    try {
        await axios.post('/api/auth/logout');
        if (setUser) {
            setUser(null);
        }
        history.push('/');
    } catch (err) {
        console.log(err);
    }
};

export default dropToken;
