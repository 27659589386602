const getMilitaryTime = ({ value, type }: { value: number; type: string }) => {
    if (type === 'am') {
        return value === 12 ? 0 : value;
    }

    if (type === 'pm') {
        return value === 12 ? value : value + 12;
    }

    return 0;
};

export default getMilitaryTime;
