import FlexContainer from './FlexContainer';
import FilterUsers from './FilterUsers';
import UserTable from './UserTable';
import Button from './Button';
import UserModal from './modals/UserModal';
import { MouseEvent, useState } from 'react';
import getUser from '../functions/getUser';
import User from '../interfaces/User';
import { useHistory } from 'react-router-dom';
import Action from '../interfaces/Action';

const Users = ({
    user,
    users,
    setUser,
    setAction,
    setLoading,
}: {
    user: User | null;
    users: User[];
    setUser: (user: User | null) => void;
    setAction: (action: Action) => void;
    setLoading: (value: boolean) => void;
}) => {
    const [input, setInput] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const history = useHistory();

    const handleEdit = (e: MouseEvent<HTMLButtonElement>) => {
        const user = getUser(e.currentTarget.children[0].className, users);
        setUser(user as User);
        setAction({ status: true, type: 'edit' });
    };

    const openModal = (e: MouseEvent<HTMLButtonElement>) => {
        const user = getUser(e.currentTarget.children[0].className, users);

        setUser(user as User);
        setIsOpen(true);
    };

    return (
        <FlexContainer>
            <FilterUsers setInput={setInput} />
            <div>
                <h3 className="user-container__text">
                    Currently registered users:
                </h3>
            </div>

            <UserTable
                users={users}
                input={input}
                handleEdit={handleEdit}
                openModal={openModal}
            />

            <Button
                color={'green'}
                margin={true}
                onClick={() => {
                    setUser(null);
                    setAction({
                        status: true,
                        type: 'create',
                    });
                }}
            >
                Add a User
            </Button>

            <Button
                color={'red'}
                margin={true}
                onClick={() => history.push('/dashboard')}
            >
                Back
            </Button>

            <UserModal
                user={user}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                setLoading={setLoading}
                setAction={setAction}
            />
        </FlexContainer>
    );
};

export default Users;
