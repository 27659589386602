import Modal from 'react-modal';
import { MouseEvent } from 'react';
import deleteUser from '../../functions/deleteUser';
import User from '../../interfaces/User';
import Button from '../Button';

const UserModal = ({
    user,
    isOpen,
    setIsOpen,
    setLoading,
    setAction,
}: {
    user: User | null;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    setLoading: (value: boolean) => void;
    setAction: any;
}) => {
    const handleDelete = async (e: MouseEvent<HTMLButtonElement>) =>
        await deleteUser(e.currentTarget.id, setIsOpen, setLoading, setAction);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            ariaHideApp={false}
            className="modal"
        >
            <header className="modal__header">
                <h1 className="modal__header--h1">
                    Do you want to delete user:{' '}
                    <span className="green-span">{user?.login}</span>
                </h1>
            </header>

            <Button
                color={'green'}
                margin={true}
                id={user?._id}
                onClick={handleDelete}
            >
                Yes
            </Button>

            <Button
                color={'red'}
                margin={true}
                onClick={() => setIsOpen(false)}
            >
                No
            </Button>
        </Modal>
    );
};

export default UserModal;
