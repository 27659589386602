import { useState, useEffect } from 'react';
import axios from 'axios';
import FeeI from '../interfaces/Fee';
import GuestI from '../interfaces/Guest';
import AllAccessGuestI from '../interfaces/AllAccessGuest';

const useFee = (
    type: string,
    { discount, points }: GuestI | AllAccessGuestI,
    day: number = new Date().getDay(),
    today: string = 'none'
) => {
    const [fee, setFee] = useState<FeeI | null>(null);
    const [loadingFee, setLoading] = useState(true);
    const [guestPoints, setGuestPoints] = useState(points || 0);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                //If points are out of range put them either on maximum or minimum value depending on the points value
                if (guestPoints < 1 || !guestPoints) {
                    setGuestPoints(0);
                }

                if (guestPoints > 5) {
                    setGuestPoints(5);
                }

                const { data } = await axios.get(
                    `/api/fees/${day}?type=${type}&points=${guestPoints}`
                );

                if (today === 'none') {
                    return setFee({
                        value: data.value,
                        _id: data._id,
                        day: day,
                        name: today,
                    });
                }

                let total = data.value;

                if (discount !== undefined) {
                    total = data.value + parseInt(discount);

                    if (total < 0) {
                        total = 0;
                    }
                }

                setFee({ value: total, day: day, name: today });
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guestPoints]);

    return {
        fee,
        setFee,
        loadingFee,
        setGuestPoints,
        setLoading,
    };
};

export default useFee;
