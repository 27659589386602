import { useState } from 'react';
import useUser from '../hooks/useUser';
import GlobalContext from '../contexts/GlobalContext';
import { Switch, BrowserRouter } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import LandingPage from '../pages/LandingPage';
import DashboardPage from '../pages/DashboardPage';
import UserPage from '../pages/user/UserPage';
import FeePage from '../pages/fee/FeePage';
import TransactionLookUpPage from '../pages/transaction/TransactionLookUpPage';
import TransactionPage from '../pages/transaction/TransactionPage';
import GuestDatabasePage from '../pages/guest/GuestDatabasePage';
import GuestCheckInPage from '../pages/guest/GuestCheckInPage';
import UpdateGuestPage from '../pages/guest/UpdateGuestPage';
import GuestCreatePage from '../pages/guest/GuestCreatePage';
import NoticeGuestPage from '../pages/guest/NoticeGuestPage';
import TosGuestPage from '../pages/guest/TosGuestPage';
import GuestConfirmationPage from '../pages/guest/GuestConfirmationPage';
import EditGuestPage from '../pages/guest/EditGuestPage';
import Tos from '../components/Tos';
import Lease from '../components/Lease';
import SignatureGuestPage from '../pages/guest/SignatureGuestPage';
import Loading from '../components/Loading';

const AppRouter = () => {
    const { user, setUser, isLoading, setLoading } = useUser();
    const [guest, setGuest] = useState(null);

    if (isLoading) {
        return <Loading loading={isLoading} />;
    }

    return (
        <GlobalContext.Provider
            value={{ user, setUser, isLoading, setLoading, guest, setGuest }}
        >
            <BrowserRouter>
                <Switch>
                    <PublicRoute exact path="/">
                        <LandingPage />
                    </PublicRoute>
                    <PrivateRoute exact path="/tos">
                        <Tos />
                    </PrivateRoute>
                    <PrivateRoute exact path="/lease">
                        <Lease />
                    </PrivateRoute>
                    <PrivateRoute exact path="/dashboard">
                        <DashboardPage />
                    </PrivateRoute>
                    <PrivateRoute exact path="/users">
                        <UserPage />
                    </PrivateRoute>
                    <PrivateRoute exact path="/fees">
                        <FeePage />
                    </PrivateRoute>
                    <PrivateRoute exact path="/transactions">
                        <TransactionLookUpPage />
                    </PrivateRoute>
                    <PrivateRoute exact path="/transactions/:date">
                        <TransactionPage />
                    </PrivateRoute>
                    <PrivateRoute exact path="/editGuest/:type">
                        <EditGuestPage />
                    </PrivateRoute>
                    <PrivateRoute exact path="/guests">
                        <GuestDatabasePage />
                    </PrivateRoute>
                    <PrivateRoute exact path="/guests/checkin/:type">
                        <GuestCheckInPage />
                    </PrivateRoute>
                    <PrivateRoute
                        exact
                        path="/guests/checkin/create/guest/:type/:random"
                    >
                        <GuestCreatePage />
                    </PrivateRoute>
                    <PrivateRoute exact path="/guests/notice">
                        <NoticeGuestPage />
                    </PrivateRoute>
                    <PrivateRoute exact path="/guests/notice/tos">
                        <TosGuestPage />
                    </PrivateRoute>
                    <PrivateRoute exact path="/guests/signature">
                        <SignatureGuestPage />
                    </PrivateRoute>
                    <PrivateRoute exact path="/guests/checkin/:type/:id/update">
                        <UpdateGuestPage />
                    </PrivateRoute>
                    <PrivateRoute exact path="/guests/confirmation/:type">
                        <GuestConfirmationPage />
                    </PrivateRoute>
                </Switch>
            </BrowserRouter>
        </GlobalContext.Provider>
    );
};

export default AppRouter;
